import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class BandModule extends Base_Model
{

    private id: number = null;
    private name: string = null;
    private question: string = null;
    private optional: string = "0";
    private fields: any = [];
    private form: any = null;
    private blockId: number = null;
    private block: number = null;
    private sort: number = null;
    
    
    public getId()
    {
        return this.id;
    }
    public getName()
    {
        return this.name;
    }
    public setName(name)
    {
        this.name = name;
        return this.name;
    }
    public getFields()
    {
        return this.fields;
    }
    public getForm()
    {
        return this.form;
    }
    public addfield(field)
    {
        this.fields.push(field);
    }
    public getQuestion()
    {
        return this.question;
    } 
    public setQuestion(question)
    {
        this.question = question;
    }
    public setOptional(optional)
    {
        this.optional = optional;
    }
    public setBlockId(id)
    {
        this.blockId = id;
    }
    public getBlockId()
    {
        return this.blockId;
    }
    public getSort()
    {
        return this.sort;
    }    
}