﻿import { Component, OnInit ,Input } from '@angular/core';
import { CommonDataService } from './../../../common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Form } from './../../../../services/Form';
import { BlockService } from './../../../../services/BlockService';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { FieldsService } from './../../../../services/FieldsService';
import { FieldsModule } from './../../../../models/FieldsModule';

import * as shape from 'd3-shape';
declare var window: any;
@Component({
  selector: 'app-paragraf',
  templateUrl: './paragraf.component.html',
  styleUrls: ['./paragraf.component.scss']
})

export class ParagrafComponent implements OnInit 
{
//    specialElements = [];
//    specialElementsCount = 0;
    seeOpt = false;
    insertSelectTitle;
    selectquestion = "";
    //insertIdentifierSelectTitle;
    @Input() statusField;
    @Input() currentBlockId;
    @Input() idForm;
    nameField;
    selectType = "Simple";
    next = false;
    @Input() currentFieldId = 0;
    currentModal = "addField";
    parragraf = "";
    nameError = false;
    copySelects = [];
    allElements = {};
    formParagrafQuestions = {};
    ckeditorContent;
    nOfOptions = [1,2,3,4,5,6,7,8,9,10];
    constructor(
    private _commondata: CommonDataService,
    translate: TranslateService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: ModalService,
    public fieldsService: FieldsService,
    ) 
    { 
        let respuesta = translate.get('Select_question').subscribe(res => { this.selectquestion = res; });
        console.log(this.currentFieldId);
    }
    ngOnInit() 
    {
        console.log("NGONINIT");
        console.log(this.currentFieldId);
        //document.querySelector("body").addEventListener("editParagrafRefresh", (e:Event) => this.onInit());
        this.onInit();     
    }
    onInit()
    {
        console.log(this.currentFieldId);
        this.ckeditorContent="";
        if(this.currentFieldId>0)
        {
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.currentModal="editFieldModal";
            let response = this.fieldsService.getParagraf(this.currentFieldId).then(
            (res:FieldsModule)=>{
                this.parragraf = res.getContent();
                (<HTMLInputElement>document.getElementById("parragrafTitle")).value = res.getQuestion();
                setTimeout(_ => this._commondata.showLoader(false), 200);
                this.ckeditorContent=this.parragraf;
                 let lthis = this;
                window.CKEDITOR.on( 'loaded', function( evt ) {
                    lthis.ckeditorContent = lthis.parragraf;
                });
//                window.CKEDITOR.document.on( 'click', function( ev )
//                        {
//                            // Prevent the click to chave any effect in the element.
//                            console.log("HERE");
//                            ev.data.preventDefault();
//                        });    
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);

            }
            );
        }
        else
        {
            setTimeout(_ => this._commondata.showLoader(false), 200);
        } 
    }
    getInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        var event = document.createEvent('Event');
        event.initEvent('editFormRefresh', true, true);
        document.querySelector("body").dispatchEvent(event);
    }
    
    openModal(id: string) {
        this.modalService.open(id);
        this.statusField = "";
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.modalService.close('addField');
        this.statusField = "";
//        this.specialElements = [];
//        this.specialElementsCount = 0;
        this.selectType = "Simple";
        this.next = false;
        this.parragraf = "";
        this.currentFieldId=0;
        this.getInit();
    }
    
    nextStep()
    {
        this.nameField = (<HTMLInputElement>document.getElementById("parragrafTitle")).value;
        
        if(this.nameField=="")
        {
            this.nameError = true;
            console.log("HERE");
            return;
        }
        else
        {
            this.nameError = false;
        }
                
        this.ckeditorContent=this.parragraf;
               
    }
    
    insertTextPlain()
    {
        let idCopy = "";
        let plainTextBtn = (<HTMLInputElement>document.getElementById("plainTextBtn")).innerHTML;
        let title = "";
        let select = (<HTMLSelectElement>document.getElementById('paragraf_select_plain_text'));
        let currentOpt = select.options[select.selectedIndex]; 
        if(currentOpt.value!="")
        {
            console.log("entre?");
            for(let i=0;i<Object.keys(this.formParagrafQuestions).length;i++)
            {
                let quest = this.formParagrafQuestions[i];
                console.log(quest);
                if(currentOpt.value ==  quest.id)
                {
                    title = quest.text;
                    idCopy = quest.id;
                }
            }     
              
        }
        else
        {
            console.log("entre!");
            console.log(this.idForm);
            title = (<HTMLInputElement>document.getElementById("insertTextPlain")).value;
            //let identifier = (<HTMLInputElement>document.getElementById("insertIdentifierTextPlain")).value = "";
            let dataLine = {
                        "question": title,
                        "text": title,
                        //"identifier": identifier,
                        "Block": ""+this.currentBlockId,
                        "form": this.idForm,
                        "textarea":{}
                    };
        }
        let button = '<input data-cke-saved-name="'+idCopy+'" name="'+"Texto_plano"+'" value="'+title+'" type="button" contenteditable="false" onClick="alert(\"hey\")"></input>';
        window.CKEDITOR.instances["editor-paragrafEditor"].insertHtml(button);
        this.parragraf = window.CKEDITOR.instances["editor-paragrafEditor"].getSnapshot();
//        this.parragraf = this.parragraf.replace("{{{x}}}", button);
        this.ckeditorContent = this.parragraf;
        setTimeout(_ => this.addParagraf("false"), 500);
       
    }
    insertSelect()
    {
        let selectBtn = (<HTMLInputElement>document.getElementById("selectBtn")).innerHTML;
        let options = [];
        let selectedOptions = document.querySelectorAll(".select-options");
        let sele = "";
        let dataLine = {
                        "question": this.insertSelectTitle,
                        "text": this.insertSelectTitle,
                        //"identifier":this.insertIdentifierSelectTitle,
                        "Block": ""+this.currentBlockId,
                        "form": this.idForm,
                        "choice":{
                            "type":this.selectType
                        }
                    };
                sele = '<select data-cke-saved-name="" name="" >';
                sele+='<option value="'+this.selectType+'" selected="selected">'+this.insertSelectTitle+'</option>';
                for(let i=0;i<selectedOptions.length;i++)
                {
                    let currentElement = (<HTMLInputElement>selectedOptions[i]);
                    let value = currentElement.value;
                    if(currentElement.getAttribute("data-img")!="")
                    {
                        value = "{{{img:"+currentElement.getAttribute("data-img")+"}}}";
                    }
                    sele+='<option value="'+value+'">'+currentElement.value+'</option>';
                    let dataOpt = {
                        "value": value,
                        //"choice": res.choice.id,
                        "text": currentElement.value
                    };

                }
                sele += '</select>';
                window.CKEDITOR.instances["editor-paragrafEditor"].insertHtml(sele);
                this.parragraf = window.CKEDITOR.instances["editor-paragrafEditor"].getSnapshot();
                //this.parragraf = this.parragraf.replace("{{{x}}}", sele);
                this.ckeditorContent = this.parragraf;
                //window.CKEDITOR.instances["editor-paragrafEditor"].setData(this.parragraf);
                setTimeout(_ => this.addParagraf("false"), 500);
    }        
    openSecondModal(id: string) {
        let filter = "";
        let idSelect = "";
        let response = this.form.getLineQuestions(this.idForm).then(
            (res: any)=>{
                console.log(res);
                this.formParagrafQuestions = res; 
                
                if(id == "textPlainModal")
                {
                    filter = "Texto_plano";
                    idSelect = "#paragraf_select_plain_text";
                }
                else
                {
                    idSelect = "#paragraf_select_simple_multi";
                    console.log(this.selectType);
                    if(this.selectType == "Simple")
                    {
                        filter = "Simple";
                    }
                    else
                    {
                        filter = "Multiple";
                    }
                }
                
                let options = '<option value="">'+this.selectquestion+'</option>';
                console.log(this.formParagrafQuestions);
                for(let i=0;i<Object.keys(this.formParagrafQuestions).length;i++)
                {
                    let quest = this.formParagrafQuestions[i];
                    console.log(quest);
                    if(filter == "Texto_plano" && quest.textarea!=null)
                    {
                        options+="<option value='"+quest.id+"'>"+quest.question+"</option>";
                    }
                    if(filter == "Simple" && quest.choice!=null)
                    {
                        options+="<option value='"+quest.id+"'>"+quest.question+"</option>";
                    }
                    if(filter == "Multiple" && quest.choice!=null)
                    {
                        options+="<option value='"+quest.id+"'>"+quest.question+"</option>";
                    }
                }
                console.log(options);
                document.querySelector(idSelect).innerHTML = options;
                this.modalService.open(id);
            
            },
            err=>{
                console.log("form_no");

            }
        );
        
        
       
    }
    
    getButtonsFromParagraf(filter)
    {
        
        let paragraf = window.CKEDITOR.instances["editor-paragrafEditor"].getSnapshot();
        console.log(paragraf);
        let parser = new DOMParser();
        let doc = parser.parseFromString(paragraf, "text/xml");
        console.log(doc);
        let query = "";
        if(filter=="Texto_plano")
        {
            let buttons = [];
            query = "input";
            let btns = doc.querySelectorAll(query);
            console.log(btns);
            for(let i=0;i<btns.length;i++)
            {
                console.log(btns);
                let data = {
                    "title":btns[i].getAttribute("value"),
                    "type":btns[i].getAttribute("name")
                };
                console.log(data);
                buttons.push(data);
            }
            console.log(buttons);
            return buttons;
        }
        else
        {
            let select = [];
            query = "select";
            let sel = (<HTMLSelectElement>doc.querySelectorAll(query));
            console.log(sel);
            for(let i=0;i<sel.length;i++)
            {
                let currentOpt = sel[i].querySelectorAll("option")[0]; 
                if(currentOpt.getAttribute("value")==filter)
                {
                    let data = {
                        "title":currentOpt.innerHTML,
                        "type":currentOpt.getAttribute("value"),
                        "select":sel[i]
                    };
                    console.log(data);
                    select.push(data);
                }
            }
            this.copySelects = select;
            console.log(select);
            return select;
        }
        
    }

    closeSecondModal(id: string) {
        (<HTMLInputElement>document.getElementById("insertTextPlain")).value = "";
        //(<HTMLInputElement>document.getElementById("insertIdentifierTextPlain")).value = "";
        this.seeOpt = false;
        
        this.modalService.close(id);
        
    }
    goToOption()
    {
        let select = (<HTMLSelectElement>document.querySelector("#paragraf_select_simple_multi"));
        let currentOpt = select.options[select.selectedIndex]; 
        let title = "";
        
        if(currentOpt.value!="")
        {
               
            let dataLine = {
                    "question": title,
                    "text": title,
                    "Block": ""+this.currentBlockId
                    
                };
            
                let sele = '<select data-cke-saved-name="'+currentOpt.value+'" name="'+currentOpt.value+'">';
                for(let y=0;y<Object.keys(this.formParagrafQuestions).length;y++)
                {
                    if(this.formParagrafQuestions[y].id==currentOpt.value)
                    {
                        console.log(this.formParagrafQuestions);
                        let currentQuestion = this.formParagrafQuestions[y];
                        this.insertSelectTitle = currentOpt.value;

                        sele+='<option value="'+currentQuestion.choice.type+'" selected="selected">'+currentQuestion.text+'</option>';
                        console.log(currentQuestion.choice);
                        console.log(currentQuestion.choice.opts);
                        for(let i=0;i<currentQuestion.choice.opts.length;i++)
                        {
                            let currentElement = currentQuestion.choice.opts[i];
                            let value = currentElement.value;
                            sele+='<option value="'+value+'">'+currentElement.text+'</option>';
                        }
                        sele += '</select>';
                    }
                }
                
                window.CKEDITOR.instances["editor-paragrafEditor"].insertHtml(sele);
                this.parragraf = window.CKEDITOR.instances["editor-paragrafEditor"].getSnapshot();
//                this.parragraf = this.parragraf.replace("{{{x}}}", sele);
                this.ckeditorContent = this.parragraf;
                //window.CKEDITOR.instances["editor-paragrafEditor"].setData(this.parragraf);
            
            
            
            setTimeout(_ => this.addParagraf("false"), 500);
            this.closeSecondModal('SelectModal');
        }
        else
        {
            console.log("entre!");
            this.insertSelectTitle = (<HTMLInputElement>document.getElementById("insertSelectTitle")).value;
            //this.insertIdentifierSelectTitle = (<HTMLInputElement>document.getElementById("insertIdentifierSelectTitle")).value;
            this.seeOpt = true;
            console.log("test");
            
            
            this.fieldsService.getAllImage().then(
            (data : any)=>{
                console.log(data);
                let option = "";
                for(let i=0;i<data.length;i++)
                {
                    let valu = data[i].split("/");
                    let imgName = valu[valu.length-1]; 
                    
                    option = option + "<div style='width: 24%;display: inline-block;' class='dropdown-item col-xl-3'><img style='width:100%;cursor:pointer;' class='addImg' data-img='"+imgName+"' src='"+data[i]+"'></div>";
                }
                
                document.querySelector("#submenu1").innerHTML = option;
                document.querySelector("#submenu2").innerHTML = option;
                document.querySelector("#submenu3").innerHTML = option;
                document.querySelector("#submenu4").innerHTML = option;
                document.querySelector("#submenu5").innerHTML = option;
                document.querySelector("#submenu6").innerHTML = option;
                document.querySelector("#submenu7").innerHTML = option;
                document.querySelector("#submenu8").innerHTML = option;
                document.querySelector("#submenu9").innerHTML = option;
                document.querySelector("#submenu10").innerHTML = option;
                
                let elements = document.getElementsByClassName("addImg");
                for(let y=0;y<elements.length;y++)
                {
                    elements[y].addEventListener("click", (e:Event) => this.selectingImg(elements[y].getAttribute("src"),elements[y].getAttribute("data-img"),elements[y].closest(".dropdown-menu").getAttribute("data-open")));
                }
            },
            (err)=>{
                
            });    
            
        }
    }
    selectingImg(src,dataImg,openId)
    {
        let imgElement = document.querySelector(openId);
        let optionElement = imgElement.getAttribute("data-leyenda");
        
        imgElement.innerHTML = "<img style='max-width:200px' src='"+src+"'>";
        document.querySelector(optionElement).setAttribute("data-img",dataImg);
        if((<HTMLInputElement>document.querySelector(optionElement)).value=="")
        {
            (<HTMLInputElement>document.querySelector(optionElement)).value=(<HTMLInputElement>document.querySelector(optionElement)).getAttribute("data-default-text");
        }
    }
    setSelectType(selectType)
    {
        this.selectType = selectType;
    }
    addParagraf(mdlclose)
    {
        
        this.nameField = (<HTMLInputElement>document.getElementById("parragrafTitle")).value;
        
        if(this.nameField=="")
        {
            this.nameError = true;
            console.log("HERE");
            return;
        }
        else
        {
            this.nameError = false;
        }
        
        this.parragraf = window.CKEDITOR.instances["editor-paragrafEditor"].getSnapshot();
        let data = {
            "question": this.nameField,
            "block": ""+this.currentBlockId,
            "content": this.parragraf,
            "form": this.idForm
        };
        console.log(data);
        if(mdlclose == "true")
        {
            this.modalService.close(this.currentModal);
        }
        setTimeout(_ => this._commondata.showLoader(true), 200);
        if(this.currentFieldId>0)
        {
            let response = this.fieldsService.updateParagraf(this.currentFieldId,data).then(
            (res:FieldsModule)=>{
                console.log(res);
                this.currentFieldId = res.getId();
                if(mdlclose=="true")
                {
                    this.getInit();
                    this.closeModal('addField');
                }
                else
                {
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.onInit();
                }
            },
            err=>{
                console.log("form_no");

            }
        );
        }
        else
        {
            let response = this.fieldsService.createParagraf(data).then(
            (res:FieldsModule)=>{
                console.log(res);
                this.currentFieldId = res.getId();
                if(mdlclose=="true")
                {
                    this.getInit();
                    this.closeModal('addField');
                }
                else
                {
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.onInit();
                }
            },
            err=>{
                console.log("form_no");

            }
        );
        }
//        this.specialElements = [];
//        this.specialElementsCount = 0;
        this.seeOpt = false;
        this.statusField="";
        this.selectType = "Simple";
        this.next = false;
        this.parragraf = "";
    }
    OpenMenu(sectionName, Wrapdiv) {
        var CurrentCls = document.getElementById(sectionName).getAttribute("class");
        if (CurrentCls == "dropdown-menu full-width")
        {
            document.getElementById(sectionName).setAttribute("class", "dropdown-menu show full-width");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "true");
            document.getElementById(Wrapdiv).setAttribute("class", "btn-group info-drop info-drop-box show full-width");
        }
        else {
            document.getElementById(sectionName).setAttribute("class", "dropdown-menu full-width");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "false");
            document.getElementById(Wrapdiv).setAttribute("class", "btn-group info-drop info-drop-box full-width");
        }
    }
    htmlEntities(str) 
    {
        return String(str).replace(/&/g, '&amp;').replace(/"/g, '&quot;');
    }
}

