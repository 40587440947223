﻿import { Component, OnInit, Input } from '@angular/core';
import { CommonDataService } from './../../../common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Form } from './../../../../services/Form';
import { BlockService } from './../../../../services/BlockService';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { FieldsService } from './../../../../services/FieldsService';
import { FieldsModule } from './../../../../models/FieldsModule';

import * as shape from 'd3-shape';

@Component({
  selector: 'app-diagrama',
  templateUrl: './diagrama.component.html',
  styleUrls: ['./diagrama.component.scss']
})
export class DiagramaComponent implements OnInit 
{
    idForm = false;
    blocks = [];
    @Input() statusField;
    @Input() currentBlockId;
    @Input() currentFieldId = 0;
    currentModal = "addField";
    nameError = false;
    constructor(
    private _commondata: CommonDataService,
    translate: TranslateService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: ModalService,
    public fieldsService: FieldsService,
    ) 
    {
        
    }
    
    ngOnInit() 
    {
        //document.querySelector("body").addEventListener("editDiagramRefresh", (e:Event) => this.onInit());
        this.onInit(); 
    }
    
    onInit() 
    {
        if(this.currentFieldId>0)
        {
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.currentModal="editFieldModal";
            let response = this.fieldsService.getDiagram(this.currentFieldId).then(
            (res:FieldsModule)=>{
                let data = JSON.parse(res.getContent());
                (<HTMLInputElement>document.querySelector("#xdiagram")).value = data.x;
                (<HTMLInputElement>document.querySelector("#ydiagram")).value = data.y;
                (<HTMLInputElement>document.querySelector("#diagramleyend")).value = data.l;
                (<HTMLInputElement>document.querySelector("#diagram_type")).value = data.t;
                (<HTMLInputElement>document.querySelector("#diagramfTitle")).value = res.getQuestion();
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
            
            
        }
        
    }
    
    getInit()
    {
        this.closeModal(this.currentModal);
        setTimeout(_ => this._commondata.showLoader(true), 200);
        var event = document.createEvent('Event');
        event.initEvent('editFormRefresh', true, true);
        document.querySelector("body").dispatchEvent(event);
        
    }

    openModal(id: string) {
        this.modalService.open(id);
        this.statusField = false;
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.statusField = false;
    }
    
    submitDialog()
    {
        let question = (<HTMLInputElement>document.getElementById("diagramfTitle")).value;
        if(question=="")
        {
            this.nameError = true;
            console.log("HERE");
            return;
        }
        else
        {
            this.nameError = false;
        }
        let content = {
            "x":(<HTMLInputElement>document.getElementById("xdiagram")).value,
            "y":(<HTMLInputElement>document.getElementById("ydiagram")).value,
            "l":(<HTMLInputElement>document.getElementById("diagramleyend")).value,
            "t":(<HTMLInputElement>document.getElementById("diagram_type")).value
        };
        
        
        let data = {
            "content" : JSON.stringify(content),
            "question" : question,
            "block" : this.currentBlockId
        };
        
        if(this.currentFieldId>0)
        {
            let response = this.fieldsService.updateDiagram(this.currentFieldId,data).then(
                (res:FieldsModule)=>{
                    console.log(res);
                    console.log("done");
                    this.getInit();
                },
                err=>{
                            console.log("form_no");

                }
            );
        }
        else
        {
            let response = this.fieldsService.createDiagram(data).then(
                (res:FieldsModule)=>{
                    console.log(res);
                    console.log("done");
                    this.getInit();
                },
                err=>{
                            console.log("form_no");

                }
            );
        }
    }
    
}
