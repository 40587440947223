import {Injectable} from '@angular/core';
import {Api} from '../providers/api';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {RequestOptions, Headers} from '@angular/http';
import {RoomModule} from '../models/RoomModule';

@Injectable()
export class RoomService 
{
    constructor(private api: Api) 
    {
        
    };

    createRoom(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/rooms', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    let room: RoomModule = new RoomModule();
                    room.initialize(res);
                    console.log(room);
                    resolve(room);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    
    deleteRoom(id)
    {
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.delete('api/rooms/'+id).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
    }
    
}

