import {Injectable} from '@angular/core';
import {Headers, RequestOptions, Http, Response} from '@angular/http';
import {HttpClientModule, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Users} from '../services/Users';
import {Api} from '../providers/api';
import {User} from '../models/user';
import {Router} from '@angular/router';
//import {AuthService, FacebookLoginProvider} from 'angular-6-social-login';
import {map} from "rxjs/operators";

@Injectable()
export class UserAuthService
{
    body;
    registerData;

    constructor(
        //public socialAuthService: AuthService,
        public router: Router,
        public api: Api,
        private http: HttpClientModule,
        private user_model: Users,
    )
    {
    }

    login(data)
    {
        let lthis = this;
        let headers = new Headers({"Content-Type": "application/x-www-form-urlencoded"});
        let options = new RequestOptions({headers: headers});

        lthis.body = "username=" + data['username'] + "&password=" + data['password'] + "&grant_type=" + environment.grantType + "&client_id=" + environment.clientId + "&client_secret=" + environment.clientSecret;

        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/login', lthis.body, options).subscribe(
                (res: any) =>
                {
                    let user = new User();
                    user.setToken(JSON.parse(res.text()).access_token);
                    user.setTokenExpire(JSON.parse(res.text()).expires_in);
                    lthis.user_model.saveLocalUser(user);
                    
                    localStorage.setItem('token', JSON.parse(res.text()).access_token);
                    console.log(JSON.parse(res.text()).access_token);
                    resolve(res);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                    localStorage.setItem('login_error', "usuario o contraseña invalida");
                    reject(err);
                }
            );
        });
    }

    registerUser(postData)
    {
        let headers = new Headers({"Content-Type": "application/json"});
        let options = new RequestOptions({headers: headers});
        this.registerData = postData;
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post("api/users", postData, options).subscribe(
                (res) =>
                {
                    resolve(res);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                    reject(err);
                }
            );
        });
    }
    
//    socialSignIn(socialPlatform: string)
//    {
//        let lthis = this;
//        return new Promise(function (resolve, reject)
//        {
//            let socialPlatformProvider;
//
//            if (socialPlatform == "facebook")
//            {
//                socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
//            }
//
//            lthis.socialAuthService.signIn(socialPlatformProvider).then(
//                (userData) =>
//                {
//                    let arr = {
//                        'client_id':environment.clientId,
//                        'client_secret':environment.clientSecret,
//                        'email': userData.email,
//                        'id': userData.id,
//                        'image': userData.image,
//                        'name': userData.name,
//                        'provider': userData.provider,
//                        'token': userData.token
//                    }
//                    console.log(arr);
//                    lthis.api.post("api/socialLogin", arr).subscribe(
//                        (res) =>
//                        {
//                            let user = new User();
//                            user.setToken(JSON.parse(res.text()).access_token);
//                            user.setTokenExpire(JSON.parse(res.text()).expires_in);
//                            localStorage.setItem('token', JSON.parse(res.text()).access_token);
//                            lthis.user_model.saveLocalUser(user);
//                            lthis.user_model.getProf().then(
//                                (res) =>
//                                {
//                                    lthis.router.navigate(['/profile']);
//                                    resolve(res);
//                                },
//                                (err) =>
//                                {
//                                    console.log(err);
//                                    reject(err);
//                                }
//                            );
//                        },
//                        (err) =>
//                        {
//                            console.log(err);
//                            reject(err);
//                        }
//                    );
//                }
//            );
//        });
//    }

    logout()
    {
        localStorage.removeItem('userProf');
        localStorage.removeItem('currentUser');
        this.user_model.removeLocalUser();
        this.router.navigate(['/']);
        window.location.reload(false);
    }

}
