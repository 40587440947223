import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class RoomModule extends Base_Model
{

    private id: number = null;
    private content: string = null;
    private col_span: number = null;
    private row_span: number = null;
    private col_pos: number = null;
    private row_pos: number = null;
    private board: string = null;
    
    public getId()
    {
        return this.id;
    }
    public getBoard()
    {
        return this.board;
    }
    public getContent()
    {
        return this.content;
    }
    public getColSpan()
    {
        return this.col_span;
    }
    public getRowSpan()
    {
        return this.row_span;
    }
    public getColPos()
    {
        return this.col_pos;
    }
    public getRowPos()
    {
        return this.row_pos;
    }
    
}