﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { Users } from '../../services/Users';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-cuentas',
  templateUrl: './cuentas.component.html',
  styleUrls: ['./cuentas.component.scss']
})
export class CuentasComponent implements OnInit {

    user;
    userError = false;
    emailError = false;
    passError = false;
    url;
    imgPreview=false;
    selectedFile;
    constructor(
    private _commondata: CommonDataService,
    private UsersService: Users
    ) { }
    ngOnInit() 
    {
        setTimeout(_ => this._commondata.showLoader(false), 200);
        this.onInit();
    }
    onInit()
    {
        setTimeout(_ => this._commondata.showLoader(false), 200);
        this.user = this.UsersService.getLocalUser();
        if(this.user.getImage()!=null)
        {
            this.url = this.UsersService.getUrl()+"/upload/profile/"+this.user.getImage();
            this.imgPreview=true;
        }
        
    }
    
    updateUser(value,section)
    {
        if(value.length>4)
        {
            let postSend;
            if(section == "username")
            {
                postSend = {
                    "username" : value
                };
            }
            if(section == "email")
            {
                postSend = {
                    "email" : value
                };
            }
            if(section == "plainPassword")
            {
                postSend = {
                    "plainPassword" : value
                };
            }
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.UsersService.updateUser(this.user.getId(),postSend).then(
                    (res: any)=>{
                        console.log(res);
                        
                        if(section == "username")
                        {
                            this.userError = false;
                        }
                        if(section == "email")
                        {
                            this.emailError = false;
                        }
                        if(section == "plainPassword")
                        {
                            this.passError = false;
                        }
                        
                        this.UsersService.getProf().then(
                            (res: any)=>{
                                this.UsersService.saveLocalUser(res);
                                setTimeout(_ => this._commondata.showLoader(false), 200);
                                this.onInit();
                            },
                            (err: any)=>{

                            }
                        );
                        
                    },
                    (err: any)=>{
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                        if(section == "username")
                        {
                            this.userError = true;
                        }
                        if(section == "email")
                        {
                            this.emailError = true;
                        }
                        if(section == "plainPassword")
                        {
                            this.passError = true;
                        }
                    });
        }
    }
    
    onFileChanged(event) {
        setTimeout(_ => this._commondata.showLoader(true), 200);
                this.selectedFile = event.target.files[0];
                
                    if (event.target.files && event.target.files[0]) {
                        this.imgPreview=true;
                        var reader = new FileReader();

                        reader.readAsDataURL(event.target.files[0]); // read file as data url

                        reader.onload = (event) => 
                        { // called once readAsDataURL is completed
                          this.url = reader.result;
                          this.uploadFile();
                        }
                    }
            }


            uploadFile() { 
                console.log("here");
                this.UsersService.uploadProfile(this.selectedFile).then(
                    (data : any)=>{
                         console.log(data)
                         this.UsersService.getProf().then(
                            (res: any)=>{
                                this.UsersService.saveLocalUser(res);
                                setTimeout(_ => this._commondata.showLoader(false), 200);
                                this.onInit();
                            },
                            (err: any)=>{

                            }
                        );
                         
                      },
                      error=>{
                        console.log(error);
                      }
                    );

            }
    
}
