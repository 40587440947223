﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../../common-data.service';
import { Form } from './../../../services/Form';
import { ProjectService } from './../../../services/ProjectService';
import { ActivatedRoute, Router } from '@angular/router';

import * as shape from 'd3-shape';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class SelectFormComponent implements OnInit {

    myforms = [];

    constructor(
    private _commondata: CommonDataService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    public projectService: ProjectService
    ) { }
    ngOnInit() 
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.form.getMyFormsActive().then(
            (res:any)=>{
               this.myforms = res;  
               console.log(this.myforms);
               setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
        
    }

    createProject(form)
    {
        let response = this.projectService.createProject(form).then(
            (res)=>{
                console.log(res);
                        this.router.navigate(['/projects/editProject/'+res["id"]]);
                        return;
            },
            err=>{
                        console.log("form_no");

            }
        );
    }

}
