﻿import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonDataService } from './../../common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Form } from './../../../services/Form';
import { BlockService } from './../../../services/BlockService';
import { FieldsService } from './../../../services/FieldsService';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { FormModule } from './../../../models/FormModule';

@Component({
  selector: 'app-editForm',
  templateUrl: './editForm.component.html',
  styleUrls: ['./editForm.component.scss']
})
export class EditFormComponent implements OnInit 
{
    @ViewChild("containerPrint") containerPrint: ElementRef;
    idForm = false;
    currentBlock = "0";
    public currentTypeField = false;
    blocks = [];
    initialBlocks = [];
    statusField;
    signupForm: FormGroup;
    editForm: FormGroup;
    subBlock: FormGroup;
    optionalBlock:FormGroup;
    currentIdCreateSubBlock = 0;
    currentBlockId = 0;
    currentFieldId = 0;
    currentFieldType = "";
    myForm:FormModule;
    formTitleEdit = false;
    blockTitle = false;
    blockDeleteId;
    fieldDelete;
    blocksStructure = [];
    blockSortAction;
    blockSort;
    blockSortFind;
    recursiveCheck = "";
    setActionColor = "";
    currentGroup="";
    myGroups;
    allFields = [];
    isEdit=false;
    editGroupId = 0;
    deleteGroupId = 0;
    mainGroupId = 0;
    conditionalId;
    conditional = [];
    formParagrafQuestions = {};
    
    constructor(
    private _commondata: CommonDataService,
    translate: TranslateService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    public block: BlockService, 
    public fieldService: FieldsService,
    private modalService: ModalService,
    private frmbuilder:FormBuilder
    ) 
    {
        this.signupForm=frmbuilder.group({  
                nameBlock:new FormControl(null, [Validators.required,Validators.minLength(3)]),
            });
        this.editForm=frmbuilder.group({  
            nameBlock:new FormControl(null, [Validators.required,Validators.minLength(3)]),
            block_id:new FormControl(null),
        });
        this.subBlock=frmbuilder.group({  
                nameBlock:new FormControl(null, [Validators.required,Validators.minLength(3)]),
            });
        this.optionalBlock=frmbuilder.group({  
                optionalBlock:new FormControl(null, [Validators.required,Validators.minLength(3)]),
            });    
        
    }
    ngOnInit() 
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        document.querySelector("body").addEventListener("editFormRefresh", (e:Event) => this.getInit());
        this.getInit();
        
    }
    
    getInit()
    {
        this.statusField="";
        this.isEdit=false;
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.route.params.subscribe(
            (resp: any[]) =>
            {
                if(resp["idForm"]>0)
                {
                    this.idForm = resp["idForm"];
                    this.getForm(this.idForm);
                }
                if (this.idForm === false)
                {
                    this.router.navigate(['/form/']);
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            }
        );

        this.signupForm.get("nameBlock").setValue("");
        this.subBlock.get("nameBlock").setValue("");
        this.editForm.get("nameBlock").setValue("");
        this.optionalBlock.get("optionalBlock").setValue("");
    }
    
    getForm(idForm)
    {
        
        let response = this.form.getForm(idForm).then(
            (res:any)=>{
                console.log(res);
                this.myForm = res["form"];
                document.querySelector("#titleEditable").innerHTML = this.myForm.getName();
                //add subclases on blocks
                let aux = [];
                for (let block of res["blocks"]) 
                {
                    for (let blocksecond of res["blocks"]) 
                    {
                        if(block.getId() == blocksecond.getIdparent())
                        {
                            console.log("Register result ", block);
                            block.setBlock(blocksecond);
                        }
                    }
                    aux.push(block);
                }
                //remove subclass blocks from first level of array
                let clear= [];
                for (let blck of aux) 
                {
                    if(blck.getIdparent() == null || blck.getIdparent() == 0)
                    {
                        clear.push(blck);
                    }
                }
                //put on the same level block and sub
                var toRet = [];
                let i = 0;
                var index = "";
                this.blocksStructure = clear;
                console.log(this.blocksStructure);
                for (let blck of clear) 
                {
                    i++;
                    index = i+".";
                    blck.nameIndex = "<strong><span>"+index+"&nbsp;&nbsp;"+blck.name+"</span></strong>";
                    toRet.push(blck);
                    
                    if(blck.getBlocks().length>0)
                    {
                        console.log(blck.getBlocks());
                        this.recursiveLevel(blck.getBlocks(),toRet,index,"&nbsp;&nbsp;&nbsp;&nbsp;");
                    }
                }
                
                setTimeout(_ => this._commondata.showLoader(false), 200);
                this.blocks = toRet;
                this.allFields = [];
                for(let block of this.blocks)
                {
                    for(let field of block.fields)
                    {
                        if(field.type!='Paragraf'){
                            
                        field.optional = block.optional;
                        this.allFields.push(field);
                        
                        }
                    }
                }
                this.allFields.sort(function(a,b){
                                        console.log(b.sort);
                                                return a.sort - b.sort;

                                    });
                console.log(this.allFields);
                this.sortFields();
                
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);
            }
        );
    }
    recursiveLevel(blocks,toRet,index,level)
    {
        let y = 0;
        for (let blck of blocks) 
        {
            y++;
            let otherIndex = index+y+".";
            blck.nameIndex = "<span>"+level+""+otherIndex+"&nbsp;&nbsp;"+blck.name+"</span>";
            console.log(toRet);
            console.log(blck);
            toRet.push(blck);
            let fields = blck.fields;
            for(let i=0;i<fields.length;i++)
            {
                fields[i].question = level+fields[i].question;
            }
            if(blck.getBlocks().length>0)
            {
                let otherLevel = "&nbsp;&nbsp;&nbsp;&nbsp;"+ level;
                this.recursiveLevel(blck.getBlocks(),toRet,otherIndex,otherLevel);
            }
        }
    }
    createBlock(data)
    {
        if(!this.signupForm.valid)
        {
            this.blockTitle = true;
        }
        else
        {
            this.blockTitle = false;
        }
        console.log(this.signupForm.valid);
        if (this.signupForm.valid) {
                this._commondata.showLoader(true);
                let response = this.block.createBlock({
                        "name": data.value.nameBlock,
                        "colums": 1,
                        "Form": this.idForm,
                        "color":this.setActionColor
                }).then(
                    (res)=>{
                        this.block.editBlock({"id":res["id"],"sort":res["id"]}).then(
                        
                            (resdata)=>{
                                this.getInit();
                                setTimeout(_ => this._commondata.showLoader(false), 10);
                                this.closeModal("newModal");
                            },
                            (err)=>{
                                
                            }
                        
                        );
                        
                        
                    },
                    err=>{
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                        
                    }
                );
        } else {
              this.validateAllFormFields(this.signupForm);
              return;
        }
    }
    createSubBlock(data)
    {
        if(!this.subBlock.valid)
        {
            this.blockTitle = true;
        }
        else
        {
            this.blockTitle = false;
        }
        console.log(this.subBlock.valid);
        if (this.subBlock.valid) {
                this._commondata.showLoader(true);
                let response = this.block.createBlock({
                        "name": data.value.nameBlock,
                        "colums": 1,
                        "Form": this.idForm,
                        "idParent":this.currentIdCreateSubBlock,
                        "color": this.setActionColor
                }).then(
                    (res)=>{
                        this.block.editBlock({"id":res["id"],"sort":res["id"]}).then(
                        
                            (resdata)=>{
                                this.closeModal("addSubBlock");
                                this.getInit();
                                setTimeout(_ => this._commondata.showLoader(false), 10);
                                this.closeModal("newModal");
                            },
                            (err)=>{
                                
                            }
                        
                        );
                    },
                    err=>{
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                        
                    }
                );
        } else {
              this.validateAllFormFields(this.subBlock);
              return;
        }
    }
    
    editSubmitBlock(data)
    {
        console.log(data);
        console.log(this.editForm.valid);
        if(!this.editForm.valid)
        {
            this.blockTitle = true;
        }
        else
        {
            this.blockTitle = false;
        }
        if (this.editForm.valid) {
                this._commondata.showLoader(true);
                console.log(this.currentBlock);
                let response = this.block.editBlock({
                        "id"  : this.currentBlock,
                        "name": data.value.nameBlock,
                        "color": this.setActionColor
                }).then(
                    (res)=>{
                        this.getInit();
                        setTimeout(_ => this._commondata.showLoader(false), 10);
                        this.closeModal("editModal");
                    },
                    err=>{
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                        
                    }
                );
        } else {
              this.validateAllFormFields(this.editForm);
              return;
        }
    }
    
    openModal(id: string,idBlock: number) {
        this.setActionColor = "";
        let allElem = document.querySelectorAll(".colorBlock");
        for(let i = 0; i < allElem.length; i++)
        {
            (<HTMLInputElement>allElem[i]).style.border = "0.3px solid";
        }
        this.modalService.open(id);
        this.currentTypeField = false;
        this.statusField = false;
        this.currentBlockId = idBlock;
    }

    closeModal(id: string) {
        console.log(id);
        //this.signupForm.actions.clearFields();
        this.signupForm.get("nameBlock").setValue("");
        this.subBlock.get("nameBlock").setValue("");
        this.editForm.get("nameBlock").setValue("");
        //(<HTMLInputElement>document.querySelector("#blockTitle")).value = "";
        console.log(this.subBlock);
        this.modalService.close(id);
        this.currentTypeField = false;
        this.statusField = false;
        
    }
    
    editBlock(block,title: string,idBlock: string)
    {
        console.log(block);
        this.signupForm.get("nameBlock").setValue(block.name);
        this.subBlock.get("nameBlock").setValue(block.name);
        this.editForm.get("nameBlock").setValue(block.name);
        this.currentBlock= block.id;
    }
    
    selectField(element)
    {
            this.currentTypeField = element;
            for (var _i = 0; _i < document.querySelectorAll(".ball-active").length; _i++) {
                document.querySelectorAll(".ball-active")[_i].className = "ball-grey";
            }
            
            console.log(document.querySelectorAll("."+element+"-element"));
            var clases = document.querySelectorAll("."+element+"-element")[0].querySelector("div");
            clases.className += " ball-active";
            
    }
    
    nextStep()
    {
        this.statusField = this.currentTypeField;
        
    }
    setCreateSubBlock(blockId)
    {
        this.currentIdCreateSubBlock = blockId;
    }
    
    validateAllFormFields(formGroup: FormGroup) {        
      Object.keys(formGroup.controls).forEach(field => { 
        const control = formGroup.get(field);            
        if (control instanceof FormControl) {            
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {       
          this.validateAllFormFields(control);           
        }
    });
                
    }
    changeCols(event,blockid)
    {
        event.target.checked;
        
        let data;
        if(event.target.checked)
        {
            data = {
                "colums":2
            }
        }
        else
        {
            data = {
                "colums":1
            }
        }
        
        let response = this.block.editColBlock(blockid,data).then(
                    (res)=>{
                    },
                    err=>{
                        
                    }
                );
    }
    changeColsField(event,fieldId)
    {
        event.target.checked;
        
        let data;
        if(event.target.checked)
        {
            data = {
                "id":fieldId,
                "columns":2
            }
        }
        else
        {
            data = {
                "id":fieldId,
                "columns":1
            }
        }
        
        let response = this.fieldService.editColFields(data).then(
                    (res)=>{
                    },
                    err=>{
                        
                    }
                );
    }
    
//    OpenAccordion(sectionName, Wrapdiv) {
//        var CurrentCls = document.querySelectorAll("[data-sub='"+Wrapdiv+"']");
//        console.log(CurrentCls);
//        for(var i=0;i<CurrentCls.length;i++)
//        {
//            var cCls=CurrentCls[i];
//            if (cCls.getAttribute("class").includes("acd-des"))
//            {
//                cCls.setAttribute("class", "acc" );
//            }
//            else 
//            {
//                cCls.setAttribute("class", "acd-des acc");
//            }
//        }
//    }
    insert_name(event){
        event.insertText("#{user_name}");
    }
    
    editFields(blockId,fieldId)
    {
        this.isEdit=true;
        console.log(blockId,fieldId);
        this.currentBlockId = blockId;
        this.currentFieldId = fieldId;
        
        let response = this.block.getTypeField(fieldId).then(
                    (res)=>{
                        console.log(res);
                        this.statusField = res;
                        this.modalService.open("editFieldModal");
//                        var event = document.createEvent('Event');
//                        event.initEvent('edit'+res+'Refresh', true, true);
//                        document.querySelector("body").dispatchEvent(event);
                    },
                    err=>{
                        
                    }
                );
    }
    editFormTitle()
    {
        if(this.formTitleEdit==false)
        {
            this.formTitleEdit = true;
        }
        else
        {
            this.myForm.setName((<HTMLInputElement>document.querySelector("#titleEdit")).value);
            this.formTitleEdit = false;
            
            let response = this.form.putForm(this.myForm).then(
                    (res:any)=>{
                        console.log(res);
                        this.myForm = res;
                        document.querySelector("#titleEditable").innerHTML = this.myForm.getName();
                    },
                    err=>{
                        
                    }
                );
            
        }
    }
    deleteBlockModal(idBlock)
    {
        this.modalService.open("deleteBlockModal");
        this.blockDeleteId = idBlock;
    }
    deleteBlock()
    {
        
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.block.deleteBlock(this.blockDeleteId).then(
            (res)=>{
                this.modalService.close("deleteBlockModal");
                this.getInit();
            },
            err=>{
                        console.log("form_no");

            }
        );
    }
    
    deleteFieldModal(field)
    {
        this.modalService.open("deleteFieldModal");
        this.fieldDelete = field;
    }
    deleteField()
    {
        
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.fieldService.deleteField(this.fieldDelete).then(
            (res)=>{
                this.modalService.close("deleteFieldModal");
                console.log(this.fieldDelete.type);
                console.log(this.fieldDelete.type=="Paragraf");
                if(this.fieldDelete.type=="Paragraf")
                {
                     let response = this.fieldService.deleteOrphanLine(this.myForm).then(
                        (res)=>{
                             setTimeout(_ => this._commondata.showLoader(false), 200);
                            this.getInit();
                        },
                        (err)=>{
                             setTimeout(_ => this._commondata.showLoader(false), 200);
                        });
                }
                else
                { 
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.getInit();
                }
            },
            err=>{
                        console.log("form_no");

            }
        );
    }
    removeSpaces(str)
    {
         return str.replace(new RegExp('&nbsp;', "g"),"");
    }
    moveSort(block,action)
    {
        
        this.blockSort = block;
        this.blockSortAction = action;
        this.recursiveCheck = "";
        
        for(let i=0;i<this.blocksStructure.length;i++)
        {
            if(this.blocksStructure[i].getId() == this.blockSort.id)
            {
                if(this.blockSortAction=="up")
                {
                    if(this.blocksStructure[i-1])
                    {
                        this.blockSortFind=this.blocksStructure[i-1];
                    }
                }
                if(this.blockSortAction=="checkUp")
                {
                    if(this.blocksStructure[i-1])
                    {
                        return true;
                    }
                }
                if(this.blockSortAction=="down")
                {
                    if(this.blocksStructure[i+1])
                    {
                        this.blockSortFind=this.blocksStructure[i+1];
                    }
                
                }
                if(this.blockSortAction=="checkDown")
                {
                    if(this.blocksStructure[i+1])
                    {
                        return true;
                    }
                }
                
            }
            if(this.blocksStructure[i].getBlocks().length>0)
            {
                 this.recursiveLevelSort(this.blocksStructure[i].getBlocks());
            }  
            
        }
        if(this.blockSortAction=="checkDown" || this.blockSortAction=="checkUp")
        {
            if(this.recursiveCheck == "true")
            {
                return true;
            }
            return false;
        }
        if(this.blockSortFind)
        {
            let setCurrentBlockSort = this.blockSort.sort;
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.block.editBlock({"id"  : this.blockSort.id,"sort": this.blockSortFind.getSort()}).then(
                (res)=>{
                    this.block.editBlock({"id"  : this.blockSortFind.getId(),"sort": setCurrentBlockSort}).then(
                            (res)=>{
                                this.getInit();
                            },
                            err=>{
                                setTimeout(_ => this._commondata.showLoader(false), 200);

                            }
                        );
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
        }
    }
    recursiveLevelSort(blocks)
    {
        for(let i=0;i<blocks.length;i++)
        {
            if(blocks[i].getId() == this.blockSort.id)
            {
                if(this.blockSortAction=="up")
                {
                    if(blocks[i-1])
                    {
                        this.blockSortFind=blocks[i-1];
                    }
                }
                if(this.blockSortAction=="checkUp")
                {
                    if(blocks[i-1])
                    {
                        this.recursiveCheck = "true";
                        return true;
                    }
                }
                if(this.blockSortAction=="down")
                {
                    if(blocks[i+1])
                    {
                        this.blockSortFind=blocks[i+1];
                    }
                }
                if(this.blockSortAction=="checkDown")
                {
                    if(blocks[i+1])
                    {
                        this.recursiveCheck = "true";
                        return true;
                    }
                }
            }
            
            if(blocks[i].getBlocks().length>0)
            {
                this.recursiveLevelSort(blocks[i].getBlocks());
            }
        }
    }
    
    setColor(elemId,color)
    {
        let allElem = document.querySelectorAll(".colorBlock");
        for(let i = 0; i < allElem.length; i++)
        {
            (<HTMLInputElement>allElem[i]).style.border = "0.3px solid";
        }
        (<HTMLInputElement>document.querySelector("#"+elemId)).style.border = "2px solid";
        this.setActionColor = color;
    }
    
    optionalQuestion(event,blockId)
    {
        this.currentBlock = blockId;
        
        if(event.target.checked)
        {
            this.modalService.open("optionalBlockModal");
        }
        else
        {
            let response = this.block.editBlock({"id":blockId,"optional":0,"question":""}).then(
                    (res)=>{
                                setTimeout(_ => this._commondata.showLoader(false), 10);
                                this.getInit();
                                this.closeModal("optionalBlockModal");
                            },
                            (err)=>{
                                
                            }
                        
                        );
        }
    
    }
    
    editOptionalBlock(data)
    {
        
        if (this.optionalBlock.valid) {
                this._commondata.showLoader(true);
                let response = this.block.editBlock({"id":this.currentBlock,"optional":1,"question":data.value.optionalBlock}).then(
                    (res)=>{
                                setTimeout(_ => this._commondata.showLoader(false), 10);
                                this.getInit();
                                this.closeModal("optionalBlockModal");
                            },
                            (err)=>{
                                
                            }
                        
                        );
        } else {
              this.validateAllFormFields(this.optionalBlock);
              return;
        }
        
    }
    resetCheck()
    {
        this.currentBlock = "";
        this.getInit();
    }
    moveGroupFieldSort(field,fieldaux)
    {
        console.log(field);
        console.log(fieldaux);
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.fieldService.editField({"id"  : field.id,"sort": fieldaux.sort}).then(
                (res)=>{
                    this.fieldService.editField({"id"  : fieldaux.id,"sort": field.sort}).then(
                            (res)=>{
                                this.getInit();
                            },
                            err=>{
                                setTimeout(_ => this._commondata.showLoader(false), 200);

                            }
                        );
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
    }
    searchNextCorrectField(fields,position,command)
    {
        let increase = 0;
        if(command=="up")
        {
            increase = -1;
        }
        if(command=="down")
        {
            increase = 1;
        }
        
            for(let i=position+increase;((i<=fields.length)&&(i>=0));i=i+increase)
            {
                if(fields[i])
                {
                    if(fields[i].type!="Line")
                    {
                        return fields[i];
                    }
                }
            }
        
    }    
    moveFieldSort(block,field,str)
    {
        let fields = block.fields
        let findField;
        for(let i=0;i<fields.length;i++)
        {
            if(fields[i].id == field.id)
            {
                if(str=="up")
                {
                    findField=this.searchNextCorrectField(fields,i,"up");
                    if(findField==false)
                    {
                        return false;
                    }
                }
                if(str=="checkUp")
                {
                    findField=this.searchNextCorrectField(fields,i,"up");
                    if(findField)
                    {
                        return true;
                    }
                }
                if(str=="down")
                {
                    findField=this.searchNextCorrectField(fields,i,"down");
                    if(findField==false)
                    {
                        return false;
                    }
                }
                if(str=="checkDown")
                {
                    findField=this.searchNextCorrectField(fields,i,"down");
                    if(findField)
                    {
                        return true;
                    }
                }
            }
        }
        if(findField)
        {
            let setCurrentBlockSort = this.blockSort.sort;
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.fieldService.editField({"id"  : field.id,"sort": findField.sort}).then(
                (res)=>{
                    this.fieldService.editField({"id"  : findField.id,"sort": field.sort}).then(
                            (res)=>{
                                this.getInit();
                            },
                            err=>{
                                setTimeout(_ => this._commondata.showLoader(false), 200);

                            }
                        );
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
        }
        else
        {
            return false;
        }
    }
    
    openAndSort()
    {
        this.modalService.open("sortFieldsModal");
        this.sortFields();
        
    }
    
    sortFields()
    {
        
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.fieldService.myGroup(this.idForm+"").then(
                (res)=>{
                    this.myGroups = res;
                    if(this.myGroups[0])
                    {
                        this.mainGroupId = this.myGroups[0].id;
                    }
                    this.myGroups.sort(function(a,b){
                                            console.log(b.sort);
                                                    return a.sort - b.sort;

                                        });
                    for(let group of this.myGroups)
                    {
                        group.fields = [];
                        for(let block of this.blocks)
                        {
                            for(let field of block.fields)
                            {
                                if(field.type!='Paragraf'){
                                    if(group.name == field.band.name)
                                    {
                                        field.optional = block.optional;
                                        if(group.fields == null)
                                        {
                                            group.fields = [];
                                        }
                                        group.fields.push(field);
                                    }

                                }
                            }
                        }
                        group.fields.sort(function(a,b){
                                            console.log(b.sort);
                                                    return a.sort - b.sort;

                                        });
                    }
                   
                    console.log(this.myGroups);
                    
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
    }
    searchNextCorrectGroup(position,command)
    {
        let increase = 0;
        if(command=="up")
        {
            increase = -1;
        }
        if(command=="down")
        {
            increase = 1;
        }
        
        for(let i=position+increase;((i<=this.myGroups.length)&&(i>=0));i=i+increase)
        {
            if(this.myGroups[i])
            {
                if(this.myGroups[i].fields.length!=0)
                {
                    return this.myGroups[i];
                }
            }
        }
        return false;    
        
    }
    moveGroupSort(group,str)
    {
        let findGroup;
        for(let i=0;i<this.myGroups.length;i++)
        {
            if(this.myGroups[i]){
                if(this.myGroups[i].fields.length!=0)
                {
                    if(this.myGroups[i].id == group.id)
                    {
                        if(str=="up")
                        {
                            findGroup=this.searchNextCorrectGroup(i,"up");
                            if(findGroup==false)
                            {
                                return false;
                            }
                        }
                        if(str=="checkUp")
                        {
                            findGroup=this.searchNextCorrectGroup(i,"up");
                            if(findGroup)
                            {
                                return true;
                            }
                        }
                        if(str=="down")
                        {
                            findGroup=this.searchNextCorrectGroup(i,"down");
                            if(findGroup==false)
                            {
                                return false;
                            }
                        }
                        if(str=="checkDown")
                        {
                            findGroup=this.searchNextCorrectGroup(i,"down");
                            if(findGroup)
                            {
                                return true;
                            }
                        }
                    }
                }
            }
            else
            {
                return false;
            }
        }
        if(findGroup)
        {
            
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.fieldService.updateBand(group.id,{"sort": findGroup.sort}).then(
                (res)=>{
                    this.fieldService.updateBand(findGroup.id,{"sort": group.sort}).then(
                            (res)=>{
                                this.getInit();
                            },
                            err=>{
                                setTimeout(_ => this._commondata.showLoader(false), 200);

                            }
                        );
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
        }
        else
        {
            return false;
        }
    }
    
    createGroup()
    {
        
        let nameGroup=(<HTMLInputElement>document.getElementById("groupName")).value;
        if(!this.checkNameInGroup(nameGroup))
        {
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.fieldService.createGroup({"name"  :nameGroup ,"form": this.idForm+""}).then(
                    (res)=>{
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                        (<HTMLInputElement>document.getElementById("groupName")).value = "";
                        this.sortFields();
                        this.getInit();
                    },
                    err=>{
                        setTimeout(_ => this._commondata.showLoader(false), 200);

                    }
                );
        }
        else
        {
            this.modalService.open("duplicateGroupNameModal");
        }    
    }
    setFieldToGroup(fieldId,band)
    {
        this.fieldService.putBandField(fieldId,band).then(
                (res)=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.getInit();
                    this.sortFields();
                    let aux = this.blocks;
                    this.blocks = [];
                    this.blocks = aux;
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
    }
    
    hasSelected(group,field)
    {
        if(field.band==null)
        {
            return null;
        }
        if(group.id==field.band.id){
            return 'selected';
        }
        else
        {
            return null;
        }
    }
    
    activeGroup(id)
    {
        this.currentGroup = id;
    }
    
    editGroup(id)
    {
        this.editGroupId = id;
    }
    
    editGroupTitle(value)
    {
        if(!this.checkNameInGroup(value))
        {
            this.fieldService.updateBand(this.editGroupId,{"name":value}).then(
                (res)=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.getInit();
                    this.sortFields();
                    let aux = this.blocks;
                    this.blocks = [];
                    this.blocks = aux;
                    this.editGroupId = 0;
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
        }
        else
        {
            this.modalService.open("duplicateGroupNameModal");
        }     
    }
    setDeleteGroup(id)
    {
        this.deleteGroupId = id;
        this.modalService.open("deleteGroupModal");
        
    }
    deleteGroup(id)
    {
        this.fieldService.deleteBand(this.deleteGroupId,this.mainGroupId).then(
                (res)=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.getInit();
                    this.sortFields();
                    let aux = this.blocks;
                    this.blocks = [];
                    this.blocks = aux;
                    this.deleteGroupId = 0;
                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);

                }
            );
    }
    
    //check name of group
    checkNameInGroup(name)
    {
        for(let i=0;i<this.myGroups.length;i++)
        {
            if(this.myGroups[i].name == name)
            {
                return true
            }
        }
        return false
    }
    
        
    openConditionModal(idModal)
    {
        this.conditionalId = "";
        this.conditional = [];
        
        let response = this.form.getLineQuestions(this.idForm).then(
            (res: any)=>{
                console.log(res);
                this.formParagrafQuestions = res;
               
                this.modalService.open(idModal);
            
            },
            err=>{
                console.log("form_no");

            }
        );
    }
    
    displayPanel(id)
    {
        if(id)
        {
            let response = this.fieldService.getConditions(id).then(
                (res: any)=>{
                    console.log(res);
                    this.conditionalId = id;
                    this.conditional = res;

                },
                err=>{
                    console.log("form_no");

                }
            );
        }
        
    }
    
    addCondition()
    {
        this.conditional.push({
            id:"",
            line : {id : "" },
            response : "",
            opts: "",
            cond : "==",
            logic : "",
            lineMainId: ""
        });
    }
    
    conditionalQuestionChange(pos,target)
    {
        console.log(pos);
        console.log(target);
        console.log(this.conditional);
        this.conditional[pos].line.id = target.value;
        
        for(let i=0;i<Object.keys(this.formParagrafQuestions).length;i++)
        {
            if(this.formParagrafQuestions[i].id == this.conditional[pos].line.id)
            {
                if(this.formParagrafQuestions[i].choice != null)
                {
                    this.conditional[pos].opts = this.formParagrafQuestions[i].choice.opts;
                }
            }
        }
    } 
    
    saveResponseCondition(pos,target)
    {
        console.log(pos);
        console.log(target);
        console.log(this.conditional);
        if(target.value != "")
        {
            this.conditional[pos].response = target.value;
        }
        
        
    }
    conditionalComparationChange(pos,target) 
    {
        console.log(pos);
        console.log(target);
        console.log(this.conditional);
        if(target.value != "")
        {
            this.conditional[pos].cond = target.value;
        }
        
    }
    saveLogicCondition(pos,target) 
    {
        console.log(pos);
        console.log(target);
        console.log(this.conditional);
        console.log(target.value);
        if(target.value != "")
        {
            this.conditional[pos].logic = target.value;
            console.log(this.conditional[pos].logic);
        }
        else
        {
            target.value = this.conditional[pos].logic;
        }
    }
    
    saveCondition()
    {
        console.log(this.conditional);
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.fieldService.editCondition(this.conditionalId,this.conditional).then(
            (res)=>
            {
                setTimeout(_ => this._commondata.showLoader(false), 200);
                
            },
            err=>{
                setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );
        
    }
    
    deleteLine(pos,id)
    {
        let auxditional = [];
        let i=0;
        for(let condition of this.conditional)
        {
            console.log(i);
            console.log(pos);
            if(i!=pos)
            {
                auxditional.push(condition);
            }
            else
            {
                
                console.log(condition);
                console.log(id);
                
                if(id!="")
                {
                    setTimeout(_ => this._commondata.showLoader(true), 200);
                    this.fieldService.deleteCondition(id).then(
                        (res)=>
                        {
                            setTimeout(_ => this._commondata.showLoader(false), 200);

                        },
                        err=>{
                            setTimeout(_ => this._commondata.showLoader(false), 200);

                        }
                    );
                    
                }
            }
            i++;
        }
        this.conditional = auxditional;
        console.log(this.conditional);
    }
    
    logicAllSelect()
    {
        let selectLogic = document.querySelectorAll(".selectlogic");
        
        for(let i = 0; i < selectLogic.length; i++)
        {
            console.log(selectLogic[i]);
            if((<HTMLOptionElement>selectLogic[i]).value == "")
            {
                return false;
            }
        }
        
        return true;
    }
    
    
}
