import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class Client extends Base_Model
{

    private id: number = null;
    private email: string = null;
    private password: string = null;
    private passconf: string = null;
    private nombre: string = null;
    private apellidos: string = null;
    private codigoPostal: string = null;
    private localidad: string = null;
    private domicilio: string = null;
    private username: string = null;
    private nif: string = null;
    private phone: string = null;
    private token: string = null;
    private tokenExpire: string = null;


    public getId()
    {
        return this.id;
    }
    public getEmail()
    {
        return this.email;
    }
    public getPassword()
    {
        return this.password;
    }
    public getPassconf()
    {
        return this.passconf;
    }
    public checkPass()
    {
        if (this.password == this.passconf)
        {
            return true;
        }
        return false;
    }
    public getName()
    {
        return this.nombre;
    }
    public getSurname()
    {
        return this.apellidos;
    }
    public getTown()
    {
        return this.localidad;
    }
    public getPostalCode()
    {
        return this.codigoPostal;
    }
    public getAddress()
    {
        return this.domicilio;
    }
    public getFullName()
    {
        if (this.getSurname() && this.getName())
        {
            return this.getName() + " " + this.getSurname();
        } 
        else if (this.getName())
        {
            return this.getName();
        }
        return '';
    }
    public getUsername()
    {
        return this.username;
    }
    public setUsername(username: string)
    {
        this.username = username;
    }
    public getNif()
    {
        return this.nif;
    }
    public getPhone()
    {
        return this.phone;
    }
    
    public getToken()
    {
        return this.token;
    }
    
    public setToken(token)
    {
        this.token = token;
        return this;
    }

    public getTokenExpire()
    {
        return this.tokenExpire;
    }
    public setTokenExpire(expire)
    {
        this.tokenExpire = expire;
        return this;
    }
}