﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { ModalService } from './../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { Users } from './../../services/Users';
import { UserAuthService } from './../../services/auth.service';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

    userForm: FormGroup;
    myUsers;
    sendPost;
    signupForm;
    userIdDelete;
    userIdEdit = 0;
    constructor(
    private _commondata: CommonDataService,
    private modalService: ModalService,
    private frmbuilder:FormBuilder,
    public user_model: Users,
    public auth: UserAuthService,
    ) { }
    ngOnInit() 
    {
        
        
        this.userForm=this.frmbuilder.group({  
                username:new FormControl(null, [Validators.required,Validators.minLength(3)]),
                email:new FormControl(null, [Validators.required,Validators.minLength(3)]),
                password:new FormControl(null, [Validators.required,Validators.minLength(3)]),
            });
                    
        this.onInit();
        
         
    }
    onInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.user_model.getMyUsers().then(
            (res:any)=>{
               this.myUsers = res;  
               console.log(this.myUsers);
               setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                        console.log("form_no");
                        setTimeout(_ => this._commondata.showLoader(false), 200);

            }
        );   
    }
    newUser()
    {
        
        this.modalService.open('newUser');
        this.userForm.get("username").setValue("");
        this.userForm.get("email").setValue("");
        this.userForm.get("password").setValue("");
        this.userIdEdit= 0;
    
    }
    
    editUserModal(user)
    {
        
        this.modalService.open('newUser');
        this.userForm.get("username").setValue(user.username);
        this.userForm.get("email").setValue(user.email);
        this.userForm.get("password").setValue(user.plainPassword);
        this.userIdEdit= user.id;
    
    }
    
    closeModal()
    {
        this.modalService.close('newUser');
    }
    
    createNewUser(data)
    {
        if (this.userForm.valid) {
              console.log('form submitted');
                this._commondata.showLoader(true);
            this.sendPost = {
                'username':data.value.username,
                'email':data.value.email,
                'plainPassword':data.value.password,
                'parent':this.user_model.getLocalUser().getId()+"",
                'TypeUser':"3",
                'enabled':true
            };
            if(this.userIdEdit==0)
            {
                let response = this.auth.registerUser(this.sendPost).then(
                (res:any)=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                    this.onInit();
                    this.modalService.close('newUser');

                },
                err=>{
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                });
                
            }
            else
            {
                this.user_model.updateUser(this.userIdEdit,this.sendPost).then(
                    (res: any)=>{
                        this.onInit();
                        this.modalService.close('newUser');
                    },
                    (err: any)=>{
                        
                    });
            }
        }else{
            this.validateAllFormFields(this.userForm);
        }
    }
    
    validateAllFormFields(formGroup: FormGroup) {        
        Object.keys(formGroup.controls).forEach(field => { 
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {            
              control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {       
              this.validateAllFormFields(control);           
            }
        });
                
    }
    
    deleteUserModal(idUser)
    {
        this.modalService.open("deleteUserModal");
        this.userIdDelete = idUser;
    }
    deleteUser()
    {
        
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.user_model.deleteUser(this.userIdDelete).then(
            (res)=>{
                this.userIdDelete=0;
                this.modalService.close("deleteUserModal");
                this.onInit();
            },
            err=>{
                        console.log("form_no");

            }
        );
    }
    
    getProfile(child)
    {
        
        if(child.image!=null)
        {
            return this.user_model.getUrl()+"/upload/profile/"+child.image;
        }
        
        return "assets/images/profile-avatar.jpg"
    }
    
}
