﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Users } from './../../services/Users';
import { ProjectService } from './../../services/ProjectService';
import { ProjectModule } from './../../models/ProjectModule';
import { BlockModule } from './../../models/BlockModule';
import {environment} from './../../environments/environment';
import * as shape from 'd3-shape';
declare var window: any;


@Component({
  selector: 'app-preview-container',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

    idProject = 0;
    preview = null;
    project;
    blocks = [];
    public dateDefault: string = "";
    count = 0;
    editorValue:string = '';
    private url: string = environment.backendUrl;
    ckeditorContent;
    
    constructor(
    private _commondata: CommonDataService,
    public router: Router,
    public route: ActivatedRoute,
    public user_model: Users,
    public projectService: ProjectService
    ){}
    ngOnInit() 
    {
        window.CKEDITOR.plugins.addExternal(
        'chart',
        '/assets/plugins/chart/',
        'plugin.js');

        this.onInit();
    }
    
    onInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.route.params.subscribe(
            (res: ProjectModule) =>
            {
                if(res["idProject"]>0)
                {
                    this.ckeditorContent="";
                    this.idProject = res["idProject"];
                    let response = this.projectService.getProject(this.idProject).then(
                        (res:any)=>{
                            this.project = res;
                            let response = this.projectService.getPreview(this.idProject).then(
                            (res:any)=>{
                                window.CKEDITOR.config.extraAllowedContent = 'img[src,alt,width,height]';
                                var tr_ele = document.createElement('div');
                                tr_ele.innerHTML = res;
                                //this.ckeditorContent=tr_ele.innerHTML;
                                let lthis = this;
                                //window.CKEDITOR.on('instanceReady', function() { console.log(tr_ele.innerHTML);lthis.ckeditorContent=tr_ele.innerHTML; });
                                setTimeout(_ => window.CKEDITOR.instances["editor-previewEditor"].insertHtml(tr_ele.innerHTML), 1000);                
                                setTimeout(_ => this._commondata.showLoader(false), 1000);
                            },
                                err=>{
                                    console.log("form_no");
                                    setTimeout(_ => this._commondata.showLoader(false), 200); 
                                }
                            );  
                        },
                            err=>{
                                console.log("form_no");
                                setTimeout(_ => this._commondata.showLoader(false), 200); 
                            }
                        ); 
                     
                 
                }
                if (this.idProject === 0)
                {
                    this.router.navigate(['/projects/']);
                    
                }
            }
        );
    }

    saveCurrentHTML()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let text = window.CKEDITOR.instances["editor-previewEditor"].getSnapshot();
        let response = this.projectService.putProject(this.idProject,{"preview":text}).then(
            (res:any)=>{
                window.CKEDITOR.instances["editor-previewEditor"].setData("");
                this.onInit();
                
            },
                err=>{
                    console.log("form_no");
                }
            );
    }
    clearCurrentHTML()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let response = this.projectService.putProject(this.idProject,{"preview":""}).then(
            (res:any)=>{
                window.CKEDITOR.instances["editor-previewEditor"].setData("");
                this.onInit();
            },
                err=>{
                    console.log("form_no");
                }
            );
    }
    pdfCurrentHTML()
    {
        return this.url+"/make/html/pdf/"+this.idProject;
    }
    getResponsesExel()
    {
        return this.url+"/make/html/projectToXls/"+this.idProject;
    }
}
