﻿import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Users } from './../services/Users';

@Injectable()
export class CommonDataService {

    public loader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    myUser;

    constructor(public user_model: Users) { }

    showLoader(value: boolean) {
        this.loader.next(value);
        this.getTriggerColor();
    } 

     setExpandDiv(id)
    {
        document.getElementById(id).setAttribute("class", "collapse show");
        document.getElementById(id).previousElementSibling.setAttribute("aria-expanded", "true");
        
    }
    
    getTriggerColor()
    {
        this.myUser = this.user_model.getLocalUser();
        console.log("dentro evento");
        if((<HTMLElement>document.getElementById("projectBackground"))!=null)
        {
            (<HTMLElement>document.getElementById("projectBackground")).style.background = "url('"+this.myUser.getConfiguration().getImage()+"')";
            (<HTMLElement>document.getElementById("projectBackground")).style.backgroundRepeat = "no-repeat";
            (<HTMLElement>document.getElementById("projectBackground")).style.backgroundSize = "cover";
        }
        if((<HTMLElement>document.getElementsByClassName("admin-header")[0])!=null)
        {
            (<HTMLElement>document.getElementsByClassName("admin-header")[0]).style.background=this.myUser.getConfiguration().getColor();
        }
        let seconds = document.getElementsByClassName("btn-secondary");
        console.log(seconds);
        for (let i = 0; i < seconds.length; i++) {
            let second = seconds[i];
            
            (<HTMLInputElement>second).style.background = this.myUser.getConfiguration().getSecond();
            (<HTMLInputElement>second).style.borderColor = this.myUser.getConfiguration().getSecond();
            (<HTMLInputElement>second).style.boxShadow = "none";
        }​
        
        let primarys = document.getElementsByClassName("btn-primary");
        console.log(primarys);
        for (let i = 0; i < primarys.length; i++) {
            let primary = primarys[i];
            console.log(this.myUser.getConfiguration().getFirst());
            (<HTMLInputElement>primary).style.background = this.myUser.getConfiguration().getFirst();
            (<HTMLInputElement>primary).style.borderColor = this.myUser.getConfiguration().getFirst();
            (<HTMLInputElement>primary).style.boxShadow = "none";
        }​
        
    }
}
