import {Injectable} from '@angular/core';
import {Api} from '../providers/api';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {RequestOptions, Headers} from '@angular/http';
import {Users} from './Users';
import {Form} from './Form';

@Injectable()
export class BlockService 
{
    constructor(private api: Api) 
    {
        
    };

    getBlocks(idForm)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/forms/'+idForm, "").map(res => res.json()).subscribe(
            (res: any) =>
            {
                console.log("Register result ", res);
                resolve(res);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    };
    getBlock(idBlock)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/blocks/'+idBlock, "").map(res => res.json()).subscribe(
            (res: any) =>
            {
                console.log("Register result ", res);
                resolve(res);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    };
    createBlock(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/blocks', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error  ', err);
                    reject(err);
                });
        });
    }
    editBlock(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            console.log(data);
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/blocks/'+data.id, data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    deleteBlock(idBlock)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.delete('api/blocks/'+idBlock).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    editColBlock(id,data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.put('api/blocks/'+id, data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }
    getTypeField(idField)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/fields/getTypeField/'+idField).subscribe(
            (res: any) =>
            {
                console.log("Register result ", res);
                resolve(res.text());
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    };

    
}

