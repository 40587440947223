﻿import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonDataService } from './../../../common-data.service';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { Users } from './../../../../services/Users';
import { UserAuthService } from './../../../../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

declare var document: any;

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
    isSearchActive:boolean = false;
    isSlideMenu:boolean = false;
    hasTarrif:boolean = false;
    urlDocs:string = null;
    styleFull = "<style>.bar-config:{background-color:{{barColor}};}</style>";
    myUser;
    defaultLanguage = "es";
    defaultLanguageName = "Español";
    translang;
    
    public scrollbarOptions = { axis: 'yx', theme: 'minimal'}

    constructor(
        public _commondata: CommonDataService,
        public user_model: Users,
        public auth: UserAuthService,
        public router: Router,
        translate: TranslateService
        ) 
    {
        this.translang = translate;
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translang.setDefaultLang(this.defaultLanguage);

        // the lang to use, if the lang isn't available, it will use the current loader to get them      
        this.translang.use(this.defaultLanguage);    
    }
    
    ngOnInit() {
        this._commondata.showLoader(true);
        this.urlDocs = this.user_model.getDocUrl();
        
        let user = this.user_model.getLocalUser();
        this.defaultLanguage = user.getLang();
        this.translang.setDefaultLang(this.defaultLanguage);
        this.translang.use(this.defaultLanguage); 
        
        switch (this.defaultLanguage) 
        {
            case 'es':
              this.defaultLanguageName="Español";
              break;
            case 'fr':
              this.defaultLanguageName="Français";
              break;
            
        }
        
        console.log(user);
        if(user.getId())
        {
            if(user.getTypeUser()==null)
            {
                this.user_model.getUserType(user.getId()).then(
                (res:any)=>{
                    user=res;
                    this.user_model.saveLocalUser(user);
                    if(user.getTarrif().isActive())
                    {
                        this.hasTarrif = true;
                    }
                },
                (err:any)=>{

                });
            }
            else
            {
                if(user.getTarrif().isActive())
                {
                    this.hasTarrif = true;
                }
            }
        }
        else
        {
                this.router.navigate(['/login']);
            
        }
        this.myUser = this.user_model.getLocalUser();
        
        document.querySelector("body").addEventListener("triggerColors", (e:Event) => this.getTriggerColor());
        
        this.getTriggerColor();
        
        
    }
    
    getTriggerColor()
    {
        this.myUser = this.user_model.getLocalUser();
        console.log("dentro evento");
        document.getElementsByClassName("navbar-brand-image")[0].src=this.myUser.getConfiguration().getLogo();
        document.getElementsByClassName("navbar-brand-image")[1].src=this.myUser.getConfiguration().getLogo();
        if((<HTMLElement>document.getElementsByClassName("admin-header")[0])!=null)
        {
            (<HTMLElement>document.getElementsByClassName("admin-header")[0]).style.background=this.myUser.getConfiguration().getColor();
        }
        let seconds = document.getElementsByClassName("btn-secondary");
        console.log(seconds);
        for (let i = 0; i < seconds.length; i++) {
            let second = seconds[i];
            
            second.style.background = this.myUser.getConfiguration().getSecond();
            second.style.borderColor = this.myUser.getConfiguration().getSecond();
            second.style.boxShadow = "none";
        }​
        
        let primarys = document.getElementsByClassName("btn-primary");
        console.log(primarys);
        for (let i = 0; i < primarys.length; i++) {
            let primary = primarys[i];
            console.log(this.myUser.getConfiguration().getFirst());
            primary.style.background = this.myUser.getConfiguration().getFirst();
            primary.style.borderColor = this.myUser.getConfiguration().getFirst();
            primary.style.boxShadow = "none";
        }​
        
    }

    toggleSearch(){
      this.isSearchActive = !this.isSearchActive;
    }
    toggleMenu(){
      this.isSlideMenu = !this.isSlideMenu;
    }

    expandCollpse(sectionName) {
        var CurrentCls = document.getElementById(sectionName).getAttribute("class");
        if (CurrentCls == "collapse" || CurrentCls == "collapse hide")
        {
            document.getElementById(sectionName).setAttribute("class", "collapse show");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "true");
        }
        else {
            document.getElementById(sectionName).setAttribute("class", "collapse hide");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "false");
        }
    }

    toggleFullscreen(elem) {
        elem = elem || document.documentElement;
        if (!document.fullscreenElement && !document['mozFullScreenElement'] &&
            !document.webkitFullscreenElement && !document['msFullscreenElement']) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element['ALLOW_KEYBOARD_INPUT']);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document['msExitFullscreen']) {
                document['msExitFullscreen']();
            } else if (document['mozCancelFullScreen']) {
                document['mozCancelFullScreen']();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }
    logout()
    {
        this.user_model.clearLocalStorage()
        
            window.location.href = "/";
       
    }
    
    getTypeUser(site)
    {
        let user = this.user_model.getLocalUser();

        switch(site) {
        case 'Forms':
            if(user.getTypeUser()<=2)
            {
                return true;
            }
            else
            {
                return false;
            }
            
        case 'Projects':
            if(user.getTypeUser()<=4)
            {
                return true;
            }
            else
            {
                return false;
            }
        case 'Users':
            if(user.getTypeUser()<=2)
            {
                return true;
            }
            else
            {
                return false;
            }
        case 'Accounts':
            if(user.getTypeUser()<=3)
            {
                return true;
            }
            else
            {
                return false;
            }
        case 'Config':
            if(user.getTypeUser()<=2)
            {
                return true;
            }
            else
            {
                return false;
            }    
      }
    }
    getProfile()
    {
        let user = this.user_model.getLocalUser();
        
        if(user.getImage()!=null)
        {
            return this.user_model.getUrl()+"/upload/profile/"+user.getImage();
        }
        
        return "assets/images/profile-avatar.jpg"
    }
    
    setDefaultLang(lang)
    {
        let user = this.user_model.getLocalUser();
        
        this.defaultLanguage = lang;
        
        user.setLang(this.defaultLanguage);
        
        this.translang.setDefaultLang(this.defaultLanguage);

        this.translang.use(this.defaultLanguage);
        
        switch (this.defaultLanguage) 
        {
            case 'es':
              this.defaultLanguageName="Español";
              break;
            case 'fr':
              this.defaultLanguageName="Français";
              break;
            
        }
        
        this.user_model.saveLocalUser(user);

    }
    
}
