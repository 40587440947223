import {Injectable} from '@angular/core';
import {Api} from '../providers/api';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {RequestOptions, Headers} from '@angular/http';
import {Users} from './Users';
import {FormModule} from '../models/FormModule';
import {BlockModule} from '../models/BlockModule';

@Injectable()
export class Form 
{
    
    
    constructor(public UsersService: Users,private api: Api, public form: FormModule, public block: BlockModule) 
    {
        this.UsersService = UsersService;
    }

    crearForm()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            console.log(this.UsersService.getLocalUser().getId());
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                
                var d = new Date();
                lthis.api.post('api/forms', {"name":"Formulario","created":d,"active":1,"user":lthis.UsersService.getLocalUser().getId()}).map(res => res.json()).subscribe(
                    (res: any) =>
                    {
                        
                        console.log("Register result ", res);
                        resolve(res);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    getMyForms()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('api/form/getMyForms', '', options).subscribe(
                    (res: any) =>
                    {
                        let toRet = [];
                        console.log("Register result ", res);
                        for (let current of JSON.parse(res.text())) {
                            lthis.form = new FormModule();
                            lthis.form.initialize(current);
                            toRet.push(lthis.form);
                        }

                        
                        resolve(toRet);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    getMyFormsActive()
    {
        if(this.UsersService.getLocalUser()!= null && this.UsersService.getLocalUser().getId()>0)
        {
            let bearer = localStorage.getItem('token');
            let headers = new Headers({"Authorization": "Bearer " + bearer});
            let options = new RequestOptions({headers: headers});
            let lthis = this;
            return new Promise(function (resolve, reject)
            {
                let opts = new RequestOptions;
                opts.withCredentials = true;
                lthis.api.get('api/form/getMyFormsActive', '', options).subscribe(
                    (res: any) =>
                    {
                        let toRet = [];
                        console.log("Register result ", res);
                        for (let current of JSON.parse(res.text())) {
                            lthis.form = new FormModule();
                            lthis.form.initialize(current);
                            toRet.push(lthis.form);
                        }

                        
                        resolve(toRet);
                    },
                    (err) =>
                    {
                        console.error('error register ', err);
                        reject(err);
                    });
            });
        }
    }
    getForm(idForm)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/forms/'+idForm, "").map(res => res.json()).subscribe(
            (res: any) =>
            {
                //create blocks
                let blocks = [];
                console.log("Register result ", res);
                lthis.form = new FormModule();
                lthis.form.initialize(res);
                console.log(res["blocks"]);
                res["blocks"].sort(function(a,b){
                    console.log(b.sort);
                            return a.sort - b.sort;
                          
                });
                
                for(let i=0;i<res["blocks"].length;i++)
                {
                    console.log(res["blocks"][i]["fields"]);
                    res["blocks"][i]["fields"].sort(function(a,b){
                        console.log(b.sort);
                                return a.sort - b.sort;

                    });
                }
                console.log(res["blocks"]);
                for (let current of res["blocks"]) {
                    lthis.block = new BlockModule();
                    lthis.block.initialize(current);
                    blocks.push(lthis.block);
                    console.log(lthis.block);
                    console.log(lthis.form);
                }
                console.log("Register masterpice ", blocks);
                resolve({"form":lthis.form,"blocks":blocks});
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    putForm(form)
    {
        let data = {
            "name" : form.getName()
        };
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.put('api/forms/'+form.getId(), data).map(res => res.json()).subscribe(
            (res: any) =>
            {
                console.log("Register result ", res);
                lthis.form = new FormModule();
                lthis.form.initialize(res);
                
                console.log("Register masterpice ", lthis.form);
                resolve(lthis.form);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    deleteForm(idForm)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.delete('api/forms/'+idForm).map(res => res.json()).subscribe(
            (res: any) =>
            {
                console.log("Register result ", res);
                
                resolve(res);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
    getLineQuestions(idForm)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/get/lines/question/'+idForm).map(res => res.json()).subscribe(
            (res: any) =>
            {
                console.log("Register result ", res);
                
                resolve(res);
            },
            (err) =>
            {
                console.error('error register ', err);
                reject(err);
            });
        });
    }
}

