import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class ConfigModule extends Base_Model
{

    private id: number = null;
    private first: string = null;
    private second: string = null;
    private color: string = null;
    private logo: string = null;
    private image: string = null;
    
    public getId()
    {
        return this.id;
    }
    
    public getFirst()
    {
        return this.first;
    }
    
    public getSecond()
    {
        return this.second;
    }
    
    public getColor()
    {
        return this.color;
    }
    
    public getLogo()
    {
        return this.logo;
    }
    
    public getImage()
    {
        return this.image;
    }
    
    public setId(id)
    {
        this.id = id;
    }
    
    public setFirst(first)
    {
        this.first = first;
    }
    
    public setSecond(second)
    {
        this.second = second;
    }
    
    public setColor(color)
    {
        this.color = color;
    }
    
    public setLogo(logo)
    {
        this.logo = logo;
    }
    
    public setImage(image)
    {
        this.image = image;
    }
    
}