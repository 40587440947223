import {Injectable} from '@angular/core';
import {Http, RequestOptions, URLSearchParams, Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import {environment} from '../environments/environment';

@Injectable()
export class Api
{
    private url: string = environment.backendUrl;
    private urlBackendDocs: string = environment.backendDocs;
    private urlWordpress: string = environment.wordpressUrl;
    private urlAnswers: string = environment.answersUrl;

    constructor(public http: Http)
    {

    }
    getDocUrl()
    {
        return this.urlBackendDocs;
    }
    getWPUrl()
    {
        return this.urlWordpress;
    }
    getAnswersUrl()
    {
        return this.urlAnswers;
    }
    get(endpoint: string, params?: any, options?: RequestOptions)
    {
        if (!options)
        {
            options = new RequestOptions();
        }

        // Support easy query params for GET requests
        if (params)
        {
            let p = new URLSearchParams();
            for (let k in params)
            {
                p.set(k, params[k]);
            }
            // Set the search field if we have params and don't already have
            // a search field set in options.
            options.search = !options.search && p || options.search;
        }

        return this.http.get(this.url + '/' + endpoint, options);
    }

    post(endpoint: string, body: any, options?: RequestOptions)
    {
        return this.http.post(this.url + '/' + endpoint, body, options);
    }
    form(endpoint: string, params: any, options?: RequestOptions)
    {
        options.headers = new Headers();
        options.headers.set("Content-Type", 'application/x-www-form-urlencoded');
        let p = new URLSearchParams();

        for (let k in params)
        {
            p.set(k, params[k]);
        }
        return this.http.post(this.url + '/' + endpoint, p.toString(), options);
    }
    put(endpoint: string, body: any, options?: RequestOptions)
    {
        return this.http.put(this.url + '/' + endpoint, body, options);
    }

    delete(endpoint: string, options?: RequestOptions)
    {
        return this.http.delete(this.url + '/' + endpoint, options);
    }

    patch(endpoint: string, body: any, options?: RequestOptions)
    {
        return this.http.put(this.url + '/' + endpoint, body, options);
    }
    public getUrl()
    {
        return this.url;
    }
}
