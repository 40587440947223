﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../../common-data.service';
import { Form } from './../../../services/Form';
import { ProjectService } from './../../../services/ProjectService';
import { ProjectModule } from './../../../models/ProjectModule';
import { ActivatedRoute, Router } from '@angular/router';
import { Users } from './../../../services/Users';

import * as shape from 'd3-shape';

@Component({
  selector: 'app-select-user',
  templateUrl: './selectUser.component.html',
  styleUrls: ['./selectUser.component.scss']
})
export class SelectUserComponent implements OnInit {

    myUsers = [];
    myProjectId = 0;
    project;
    constructor(
    private _commondata: CommonDataService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    public projectService: ProjectService,
    public UserService:Users
    ) { }
    ngOnInit() 
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.route.params.subscribe(
            (res: ProjectModule) =>
            {
                if(res["idProject"]>0)
                {
                    this.myProjectId = res["idProject"];
                    let response = this.projectService.getProject(this.myProjectId).then(
                        (res:any)=>{
                            console.log(res);
                            this.project = res;
                            let response = this.UserService.getMyUsers().then(
                                (res:any)=>{
                                   this.myUsers = res;  
                                   console.log(this.myUsers);
                                   setTimeout(_ => this._commondata.showLoader(false), 200);
                                },
                                err=>{
                                            console.log("form_no");
                                            setTimeout(_ => this._commondata.showLoader(false), 200);

                                }
                            );
                        },
                        err=>{
                            console.log("form_no");
                        }
                    );    
                }
                else
                {
                    this.router.navigate(['/projects/']);
                }
                
            }
        );   
        
    }

    createProject(idform)
    {
        let response = this.projectService.createProject(idform).then(
            (res)=>{
                console.log(res);
                        this.router.navigate(['/projects/editProject/'+res["id"]]);
                        return;
            },
            err=>{
                        console.log("form_no");

            }
        );
    }
    
    setUserToProject(idUser)
    {
        let project: ProjectModule = new ProjectModule();
        this.project.setUser(""+idUser);
        let response = this.projectService.putProject(this.project.getId(),{"user":this.project.getUser()}).then(
            (res)=>{
                console.log(res);
                        this.router.navigate(['/projects/editProject/'+res["id"]]);
                        return;
            },
            err=>{
                        console.log("form_no");

            }
        );
    }

}
