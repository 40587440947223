﻿import { Component, OnInit, Input } from '@angular/core';
import { CommonDataService } from './../../../common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Form } from './../../../../services/Form';
import { BlockService } from './../../../../services/BlockService';
import { FieldsService } from './../../../../services/FieldsService';
import { RoomService } from './../../../../services/RoomService';
import { RoomModule } from './../../../../models/RoomModule';
import { FieldsModule } from './../../../../models/FieldsModule';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';

import * as shape from 'd3-shape';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit 
{
    idForm = false;
    blocks = [];
    @Input() statusField;
    @Input() currentBlockId;
    nameField;
    next = false;
    showbuttons = false;
    cellx=0;
    celly=0;
    boardArray = null;
    boardArrayCopyToDelete;
    setActionTable = "";
    setActionColor = "";
    editCellSpan1: string = "";
    editCellSpan2: string = "";
    @Input() currentFieldId = 0;
    currentModal = "addField";
    loadResponseTime = 0;
    leyenda = "";
    nameError = false;
    writeHere = "";
    constructor(
    private _commondata: CommonDataService,
    translate: TranslateService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: ModalService,
    public fieldsService: FieldsService,
    public roomService: RoomService,
    ) 
    {
        this.writeHere = translate.instant('Escribe_aqui');
    }
    ngOnInit() 
    {
        console.log(this.currentBlockId);
        //document.querySelector("body").addEventListener("editBoardRefresh", (e:Event) => this.onInit());
        setTimeout(_ => this._commondata.showLoader(false), 200);
        this.onInit();
    }
    onInit()
    {
        if(this.currentFieldId>0)
        {
            this.currentModal="editFieldModal";
            setTimeout(_ => this._commondata.showLoader(true), 200);
            let response = this.fieldsService.getBoard(this.currentFieldId).then(
                (res:any)=>{
                    console.log(res);
                    (<HTMLInputElement>document.getElementById("boardTitle")).value = res["question"];
                    //(<HTMLInputElement>document.getElementById("boardleyend")).value = res["content"];
                    if(JSON.parse(res["content"])){
                        if(JSON.parse(res["content"]).l)
                        {
                            this.leyenda = JSON.parse(res["content"]).l;
                        }
                        else
                        {
                            this.leyenda = "";
                        }
                    }
                    console.log(res["rooms"]);
                    console.log(res["rooms"].length);
                    this.boardArray = [];
                    let calls = 0;
                    for(let i=0;i<res["rooms"].length;i++)
                    {
                        this.loadResponseTime++;
                        let roomArray=res["rooms"];
                        console.log(res);
                        let cell = [];
                        cell["board"] = res["rooms"][i]["board"];
                        cell["colPos"] = res["rooms"][i]["colPos"];
                        cell["colSpan"] = res["rooms"][i]["colSpan"];
                        cell["content"] = res["rooms"][i]["content"];
                        cell["rowPos"] = res["rooms"][i]["rowPos"];
                        cell["rowSpan"] = res["rooms"][i]["rowSpan"];
                        cell["border"] = res["rooms"][i]["border"];
                        cell["background"] = res["rooms"][i]["background"];
                        cell["font"] = res["rooms"][i]["font"];
                        cell["id"] = res["rooms"][i]["id"];
                        cell["status"] = res["rooms"][i]["status"];

                        if(typeof this.boardArray[res["rooms"][i]["rowPos"]] === 'undefined') {
                            this.boardArray[res["rooms"][i]["rowPos"]] = [];
                        }
                        this.boardArray[res["rooms"][i]["rowPos"]][res["rooms"][i]["colPos"]] = cell;
                        if(this.cellx<+(+res["rooms"][i]["rowPos"])+1)
                        {
                            this.cellx = +(+res["rooms"][i]["rowPos"])+1;
                        }
                        if(this.celly<+(+res["rooms"][i]["colPos"])+1)
                        {
                            this.celly = +(+res["rooms"][i]["colPos"])+1;
                        }
                        console.log(this.boardArray);
                          
                            
                    }
                    if(this.currentFieldId>0)
                        {
                            this.boardArrayCopyToDelete = this.boardArray;
                            setTimeout(_ => this.refreshTable(), 500);
                            this.showbuttons=true;
                            this.refreshTable();
                           
                        }
                             setTimeout(_ => this._commondata.showLoader(false), 200); 
                    
                },
                err=>{
                    console.log("form_no");

                }
            );
        }
        else
        {
            setTimeout(_ => this._commondata.showLoader(false), 200);
        }
    }
    
    getInit()
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        var event = document.createEvent('Event');
        event.initEvent('editFormRefresh', true, true);
        document.querySelector("body").dispatchEvent(event);
    }
   
    openModal(id: string) {
        this.modalService.open(this.currentModal);
        this.statusField = false;
    }

    closeModal() {
        this.modalService.close(this.currentModal);
        this.statusField = false;
    }
    nextStep()
    {
        this.nameField = (<HTMLInputElement>document.getElementById("boardTitle")).value;
        
        if(this.nameField=="")
        {
            this.nameError = true;
            console.log("HERE");
            return;
        }
        else
        {
            this.nameError = false;
        }
        
        this.next = true;
        
        if(this.currentFieldId>0)
        {
            this.boardArrayCopyToDelete = this.boardArray;
            setTimeout(_ => this.refreshTable(), 500);
            this.showbuttons=true;
        }
    }
    
    closeLoad()
    {
        this.loadResponseTime = this.loadResponseTime - 1;
        if(this.loadResponseTime == 0)
        {
            setTimeout(_ => this._commondata.showLoader(false), 200);
        }
    }
    
    changeXY()
    {
        this.cellx = +(<HTMLInputElement>document.getElementById("xtable")).value;
        this.celly = +(<HTMLInputElement>document.getElementById("ytable")).value;
        
        
        
        if(this.cellx>0 && this.celly>0)
        {
            if(this.boardArray==null)
            {
                this.boardArray = [];
            }
            
            let boardAux = [];

            for(let i: number = 0; i < this.cellx; i++) {
                    boardAux[i] = [];
                for(let j: number = 0; j< this.celly; j++) {
                    let cell = [];
                    console.log(this.boardArray[i]);
                    if(this.boardArray[i])
                    {
                        if(this.boardArray[i][j])
                        {
                            boardAux[i][j] = this.boardArray[i][j];
                        }
                        else
                        {
                            cell["text"] = "";
                            cell["colPos"] = +0;
                            cell["rowPos"] = +0;
                            cell["colSpan"] = +1;
                            cell["rowSpan"] = +1;
                            cell["border"] = "#000000";
                            cell["background"] = "white";
                            cell["font"] = "#000000";
                            cell["content"] = "";
                            cell["board"] = "";
                            cell["status"] = "";
                            boardAux[i][j] = cell;
                        }
                    }
                    else
                    {
                        cell["text"] = "";
                        cell["colPos"] = +0;
                        cell["rowPos"] = +0;
                        cell["colSpan"] = +1;
                        cell["rowSpan"] = +1;
                        cell["border"] = "#000000";
                        cell["background"] = "white";
                        cell["font"] = "#000000";
                        cell["content"] = "";
                        cell["board"] = "";
                        cell["status"] = "";
                        boardAux[i][j] = cell;
                    }
                    
                }
            }
            this.boardArray = boardAux;
            this.refreshTable();
            this.setFunction(this.setActionColor);
            this.showbuttons = true;
        }
        else
        {
            document.getElementById("BoardCreation").innerHTML="";
            this.showbuttons = false;
        }
    }
    
    
    
    editCell(elemId)
    {
        console.log("HERE");
        if(this.editCellSpan1 == "")
        {
            let elem = (<HTMLInputElement>document.querySelector("#"+elemId));
            if(elem.getAttribute("rowspan")=="1" && elem.getAttribute("colspan") == "1")
            {
                this.editCellSpan1 = elemId;
                console.log(this.editCellSpan1);
                elem.style.border = "2px solid";
            }
            return;
        }
        if(this.editCellSpan2 == "")
        {
            let elem = (<HTMLInputElement>document.querySelector("#"+elemId));
            if(elem.getAttribute("rowspan")=="1" && elem.getAttribute("colspan")=="1")
            {
                this.editCellSpan2 = elemId;
                console.log(this.editCellSpan2);
            }
            else
            {
                return;
            }
        }
        
        let fElement = document.querySelector("#"+this.editCellSpan1);
        let sElement = document.querySelector("#"+this.editCellSpan2);
        
        let fx = +fElement.getAttribute("data-x");
        let sx = +sElement.getAttribute("data-x");
        
        let fy = +fElement.getAttribute("data-y");
        let sy = +sElement.getAttribute("data-y");
        //change order of clicks
        if(fx>sx)
        {
            let aux = fx;
            fx = sx;
            sx = aux;
        }
        
        if(fy>sy)
        {
            let aux = fy;
            fy = sy;
            sy = aux;
        }
        /////
        let compare_fY = 0;
        let compare_sY = 0;
        
        console.log(fy);
        console.log(sy);
        for(var z=0;z<=+fy;z++)
        {
            compare_fY=+compare_fY+this.boardArray[fx][z]["colSpan"]-1;
            console.log(this.boardArray[fx][z]);
            if(this.boardArray[fx][z]["rowSpan"]-1>0)
            {
                for(let y=0;y<this.boardArray[fx][z]["rowSpan"];y++)
                {
                    if((fx + y)==sx)
                    {
                        compare_sY = +compare_sY+this.boardArray[fx][z]["colSpan"];
                    }
                }
            }
        }
        compare_fY = +compare_fY+fy;
        for(let i=0;i<=+sy;i++)
        {
            console.log(i);
            console.log(compare_sY);
            compare_sY=+compare_sY+this.boardArray[sx][i]["colSpan"]-1;
        }
        compare_sY = +compare_sY+sy;
        console.log(compare_fY);
        console.log(compare_sY);
        if(fx<sx)
        {
            console.log("fx<sx");
            let calc = +sx-(+fx);
            for(let i=1;i<=calc;i++)
            {
                console.log(fx+i);
                let currentNode = +fx+i;
                this.boardArray[currentNode][fy]["status"]="merge";
                if(fy<sy)
                {
                    let calc = +sy-(+fy);
                    for(let y=1;y<=calc;y++)
                    {
                        this.boardArray[currentNode][+fy+y]["status"]="merge";
                    }
                }
                if(sy<fy)
                {
                    let calc = +fy-(+sy);
                    for(let y=1;y<=calc;y++)
                    {
                        this.boardArray[currentNode][+sy+y]["status"]="merge";
                    }
                }
            }
            this.boardArray[fx][fy]["rowSpan"] = calc+1;
        }
        if(sx<fx)
        {
            console.log("sx<fx");
            let calc = +fx-(+sx);
            for(let i=1;i<=calc;i++)
            {
                console.log(sx+i);
                let currentNode = +sx+i;
                this.boardArray[currentNode][sy]["status"]="merge";
                if(fy<sy)
                {
                    let calc = +sy-(+fy);
                    for(let y=1;y<=calc;y++)
                    {
                        this.boardArray[currentNode][+fy+y]["status"]="merge";
                    }
                }
                if(sy<fy)
                {
                    let calc = +fy-(+sy);
                    for(let y=1;y<=calc;y++)
                    {
                        this.boardArray[currentNode][+sy+y]["status"]="merge";
                    }
                }
            }
            this.boardArray[sx][sy]["rowSpan"] = calc+1;
        }
        if(compare_fY<compare_sY)
        {
            console.log("fy<sy");
            let calc = +sy-(+fy);
            for(let i=1;i<=calc;i++)
            {
                console.log("in");
                this.boardArray[fx][fy+i]["status"] = "merge";
            }
            this.boardArray[fx][fy]["colSpan"] = calc+1;
            console.log(this.boardArray[fx]);
        }
        if(compare_sY<compare_fY)
        {
            console.log("sy<fy");
            let calc = +fy-(+sy);
            for(let i=1;i<=calc;i++)
            {
                this.boardArray[sx][sy+i]["status"] = "merge";
            }
            this.boardArray[sx][sy]["colSpan"] = calc+1;
        }
        this.refreshTable();
        this.editCellSpan1 = "";
        this.editCellSpan2 = "";
    }
    
    refreshTable()
    {
        let str = '<div class="table-responsive mt-15"><table class="table text-right mb-0" style="border-spacing: 10px;border-collapse: separate;">';
        
        for(let i=0;i<this.cellx;i++)
        {
            str = str + "<tr> ";
            
            for(let j=0;j<this.celly;j++)
            {
                if(this.boardArray[i][j] && this.boardArray[i][j]["status"]!="merge")
                {
                    let elemId="x"+i+"y"+j;
                    let style = 'style="padding:3px;border-radius: 10px;border:1px solid '+this.boardArray[i][j]["border"]+';background-color:'+this.boardArray[i][j]["background"]+'"';
                    this.boardArray[i][j]["text"] = '<td '+style+' class="btn-secondary" id="'+elemId+'_td" data-x="'+i+'" data-y="'+j+'" rowspan="'+this.boardArray[i][j]["rowSpan"]+'" colspan="'+this.boardArray[i][j]["colSpan"]+'" > '+'\n\
                                                        <input id="'+elemId+'_input" placeholder="'+this.writeHere+'" type="text" data-x="'+i+'" data-y="'+j+'" class="addChangeValue form-control submit-table" style="padding: .375rem .75rem;display:inline-block;background-color:'+this.boardArray[i][j]["background"]+';color:'+this.boardArray[i][j]["font"]+'" value="'+this.boardArray[i][j]["content"]+'">'+" </td>";
                    str = str + this.boardArray[i][j]["text"];
                    
                    this.boardArray[i][j]["rowPos"] = i;
                    this.boardArray[i][j]["colPos"] = j;
                }
                console.log(this.boardArray[i][j]["content"]);
            }
            str = str + " </tr>";
        }
        str = str + " </table></div>";
        console.log(this.boardArray);
        document.getElementById("BoardCreation").innerHTML=str;
        let elements = document.getElementsByClassName("addEvent");
        
        this.ActivateAction();
//        for(let z=0;z<document.querySelectorAll(".addChangeValue").length;z++)
//        {
//            let current = document.querySelectorAll(".addChangeValue")[z];
//            current.addEventListener("change", (e:Event) => this.editInputCell(current.getAttribute("id")));
//        }
    }
//    editInputCell(inputId)
//    {
//        console.log("HERE");
//        let current=document.getElementById(inputId);
//        this.boardArray[current.getAttribute("data-x")][current.getAttribute("data-y")]["content"] = (<HTMLInputElement>current).value;
//    }
    
    submitTable()
    {
        
        this.nameField = (<HTMLInputElement>document.getElementById("boardTitle")).value;
        
        if(this.nameField=="")
        {
            this.nameError = true;
            console.log("HERE");
            return;
        }
        else
        {
            this.nameError = false;
        }
        setTimeout(_ => this._commondata.showLoader(true), 200);
        let elements = document.getElementsByClassName("submit-table");
        for(let i=0;i<elements.length;i++)
        {
            let x=elements[i].getAttribute("data-x");
            let y=elements[i].getAttribute("data-y");
            
            this.boardArray[x][y]["content"] = (<HTMLInputElement>elements[i]).value;
        }
        
        let data = {
            "Block":this.currentBlockId+"",
            "content":JSON.stringify({"l":(<HTMLInputElement>document.querySelector("#boardleyend")).value}),
            "question":this.nameField,
            "rooms":[]
        };
        
        if(this.currentFieldId>0)
        {
            for(let i=0;i<this.boardArray.length;i++)
            {
                for(let j=0;j<this.boardArray[i].length;j++)
                {
                    console.log(this.boardArray[i][j]);
                    let dataRoom = {
                        "board":this.boardArray[i][j]["board"],
                        "colPos":this.boardArray[i][j]["colPos"],
                        "colSpan":this.boardArray[i][j]["colSpan"],
                        "content":this.boardArray[i][j]["content"],
                        "rowPos":this.boardArray[i][j]["rowPos"],
                        "rowSpan":this.boardArray[i][j]["rowSpan"],
                        "border":this.boardArray[i][j]["border"],
                        "background":this.boardArray[i][j]["background"],
                        "font":this.boardArray[i][j]["font"],
                        "status":this.boardArray[i][j]["status"],
                        "id":this.boardArray[i][j]["id"],
                    };
                    data.rooms.push(dataRoom);
                }
                
            }
            this.fieldsService.updateBoard(this.currentFieldId,data).then(
                    (res:RoomModule)=>{
                        console.log(res);
                        console.log("done");
                        this.getInit();
                        this.closeModal();
                        this.next = false;
                    },
                    err=>{
                        console.log("form_no");

                    }
                );
            console.log("done");
        }
        else
        {
            let loadCount = 0;
            for(let i=0;i<this.boardArray.length;i++)
            {
                for(let j=0;j<this.boardArray[i].length;j++)
                {
                    console.log(this.boardArray[i][j]);
                    let dataRoom = {
                        "board":this.boardArray[i][j]["board"],
                        "colPos":this.boardArray[i][j]["colPos"],
                        "colSpan":this.boardArray[i][j]["colSpan"],
                        "content":this.boardArray[i][j]["content"],
                        "rowPos":this.boardArray[i][j]["rowPos"],
                        "rowSpan":this.boardArray[i][j]["rowSpan"],
                        "border":this.boardArray[i][j]["border"],
                        "background":this.boardArray[i][j]["background"],
                        "font":this.boardArray[i][j]["font"],
                        "status":this.boardArray[i][j]["status"]
                    };
                    data.rooms.push(dataRoom);
                }
            }
            let response = this.fieldsService.createBoard(data).then(
                (res:FieldsModule)=>{
                    console.log(res);
                    console.log("done");
                    this.getInit();
                    this.closeModal();
                    this.next = false;
                },
                err=>{
                            console.log("form_no");

                }
            );
        }
        
        
    }
    
    nextUpdateBoard(count,data)
    {
        if(count == 0)
        {
            let response = this.fieldsService.updateBoard(this.currentFieldId,data).then(
                (res:FieldsModule)=>{
                    console.log(res);
                    let loadCount = 0;
                    for(let i=0;i<this.boardArray.length;i++)
                    {
                        for(let j=0;j<this.boardArray[i].length;j++)
                        {
                            loadCount++;
                            this.boardArray[i][j]["board"] = res.getId()+"";
                            console.log(this.boardArray[i][j]);
                            let dataRoom = {
                                "board":this.boardArray[i][j]["board"],
                                "colPos":this.boardArray[i][j]["colPos"],
                                "colSpan":this.boardArray[i][j]["colSpan"],
                                "content":this.boardArray[i][j]["content"],
                                "rowPos":this.boardArray[i][j]["rowPos"],
                                "rowSpan":this.boardArray[i][j]["rowSpan"],
                                "border":this.boardArray[i][j]["border"],
                                "background":this.boardArray[i][j]["background"],
                                "font":this.boardArray[i][j]["font"],
                                "status":this.boardArray[i][j]["status"]
                            };
                            this.roomService.createRoom(dataRoom).then(
                                (res:RoomModule)=>{
                                    console.log(res);
                                    loadCount--;
                                    this.loadEditForm(loadCount);
                                },
                                err=>{
                                            console.log("form_no");

                                }
                            );
                        }
                    }
                    console.log("done");
                    
                },
                err=>{
                            console.log("form_no");

                }
            );
        }
    }
    
    loadEditForm(count)
    {
        if(count == 0)
        {
            this.closeModal();
            this.next = false;
            this.getInit();
        }
    }
    
    
    setFunction(action)
    {
        let allElem = document.querySelectorAll(".colorBlock");
        for(let i = 0; i < allElem.length; i++)
        {
            (<HTMLInputElement>allElem[i]).style.border = "0.3px solid";
        }
        
        if(this.setActionTable == action)
        {
            this.setActionTable = "";
            this.setActionColor = "";
            this.refreshTable();
        }
        else
        {
            this.setActionTable = action;
            this.setActionColor = "";
            this.refreshTable();
        }
        this.ActivateAction();
    }
    
    ActivateAction()
    {
        
        
        let allTds = document.querySelectorAll("#BoardCreation td");
        for(let i = 0; i < allTds.length; i++)
        {
            allTds[i].querySelector("input").readOnly = false;
            let el = allTds[i];
            let elClone = el.cloneNode(true);
            el.parentNode.replaceChild(elClone, el);
            
        }
        
        if(this.setActionTable == "border")
        {
            allTds = document.querySelectorAll("#BoardCreation td");
            for(let i = 0; i < allTds.length; i++)
            {
                allTds[i].querySelector("input").removeAttribute("placeholder");
                allTds[i].addEventListener("click", (e:Event) => this.borderCell(allTds[i].getAttribute("id")));
                allTds[i].querySelector("input").readOnly = true;
            }
        }
        
        if(this.setActionTable == "back")
        {
            allTds = document.querySelectorAll("#BoardCreation td");
            for(let i = 0; i < allTds.length; i++)
            {
                allTds[i].querySelector("input").removeAttribute("placeholder");
                allTds[i].addEventListener("click", (e:Event) => this.backCell(allTds[i].getAttribute("id")));
                allTds[i].querySelector("input").readOnly = true;
            }
        }
        
        if(this.setActionTable == "font")
        {
            allTds = document.querySelectorAll("#BoardCreation td");
            
            for(let i = 0; i < allTds.length; i++)
            {
                allTds[i].querySelector("input").removeAttribute("placeholder");
                allTds[i].addEventListener("click", (e:Event) => this.fontCell(allTds[i].getAttribute("id")));
                allTds[i].querySelector("input").readOnly = true;
            }
        }
        
        if(this.setActionTable == "merge")
        {
            allTds = document.querySelectorAll("#BoardCreation td");
            for(let i = 0; i < allTds.length; i++)
            {
                allTds[i].addEventListener("click", (e:Event) => this.editCell(allTds[i].getAttribute("id")));
                allTds[i].querySelector("input").readOnly = true;
                allTds[i].querySelector("input").removeAttribute("placeholder");
                if(allTds[i].getAttribute("colspan")!="1" || allTds[i].getAttribute("rowspan")!="1")
                {
                    (<HTMLInputElement>allTds[i]).style.background = "#444";
                    allTds[i].querySelector("input").style.background = "#444";
                }
            }
        }
        if(this.setActionTable == "")
        {
            allTds = document.querySelectorAll("#BoardCreation td");
            for(let i = 0; i < allTds.length; i++)
            {
                allTds[i].addEventListener("change", (e:Event) => this.writeCell(allTds[i].getAttribute("id")));
            }
        }
    }
    
    setColor(elemId,color)
    {
        let allElem = document.querySelectorAll(".colorBlock");
        for(let i = 0; i < allElem.length; i++)
        {
            (<HTMLInputElement>allElem[i]).style.border = "0.3px solid";
        }
        (<HTMLInputElement>document.querySelector("#"+elemId)).style.border = "2px solid";
        this.setActionColor = color;
    }
    
    writeCell(elemId)
    {
        if(this.setActionColor == "")
        {
            let fElement = document.querySelector("#"+elemId);
            let fx = fElement.getAttribute("data-x");
            let fy = fElement.getAttribute("data-y");
            this.boardArray[fx][fy]["content"] = (<HTMLInputElement>fElement.querySelector("input")).value;
            console.log(this.boardArray[fx][fy]["content"]);
            this.refreshTable();
        }
    }
    
    borderCell(elemId)
    {
        if(this.setActionColor != "")
        {
            let fElement = document.querySelector("#"+elemId);
            let fx = fElement.getAttribute("data-x");
            let fy = fElement.getAttribute("data-y");
            this.boardArray[fx][fy]["border"] = this.setActionColor;
            this.refreshTable();
        }
    }
    backCell(elemId)
    {
        if(this.setActionColor != "")
        {
            let fElement = document.querySelector("#"+elemId);
            let fx = fElement.getAttribute("data-x");
            let fy = fElement.getAttribute("data-y");
            this.boardArray[fx][fy]["background"] = this.setActionColor;
            this.refreshTable();
        }
    }
    fontCell(elemId)
    {
        if(this.setActionColor != "")
        {
            let fElement = document.querySelector("#"+elemId);
            let fx = fElement.getAttribute("data-x");
            let fy = fElement.getAttribute("data-y");
            this.boardArray[fx][fy]["font"] = this.setActionColor;
            this.refreshTable();
        }
    }
    
}
