// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    name: "default",
    backendUrl: "https://api.essp-online.com",
    grantType: "password",
    clientSecret: "5pam1st9z5c8o8gw0408s4k488koc00o4sgc8w4g8wg0k4gk4c",
    clientId: "1_1loz1k5dqwcgw844gsck88kkkcco0k884w0w800cgoo4o0k8co",
    backendDocs:"https://api.essp-online.com/upload/docs/",
    answersUrl:"https://api.essp-online.com/upload/answers/",
    wordpressUrl: "https://nathila-wp.essedi.es/"
};
