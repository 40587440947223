import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {TarrifTypeModule} from './tarrifTypeModule';

export class TarrifModule extends Base_Model
{

    private id: number = null;
    private dateEnd: string = null;
    private dateStart: string = null;
    private tarrifType: TarrifTypeModule = new TarrifTypeModule();
    
    public getId()
    {
        return this.id;
    }
    
    public getDateEnd()
    {
        return this.dateEnd;
    }
    
    public getDateStart()
    {
        return this.dateStart;
    }
    
    public isActive()
    {
        if(this.getId() != null && this.getDateEnd() != null && this.getDateStart() != null)
        {
            var d1 = new Date(this.getDateEnd()).getTime();
            var d2 = new Date(this.getDateStart()).getTime();
            var d3 = new Date().getTime();
                      
            if((d3 <= d1 && d3 >= d2))
            {
                return true;
            }
            else
            {
                return false;
            }
            
        }
        else
        {
            return false;
        }
    }
    
    checkNewPayment(payment)
    {
        console.log("id "+this.getId());
        console.log("end "+this.getDateEnd());
        console.log("start "+this.getDateStart());
        if(this.getId() != null && this.getDateEnd() != null && this.getDateStart() != null)
        {
            var d1 = new Date(this.getDateStart()).getTime();
            var d2 = new Date(payment.getCreatedDate()).getTime();
                  console.log(d1) ;   
                  console.log(d2) ;   
            if((d1 < d2))
            {
                return true;
            }
            else
            {
                return false;
            }
            
        }
        else
        {
            return false;
        }
    }
    
    public setTarrifType( tarrifType )
    {
        this.tarrifType = tarrifType;
        return this;
    }
    public getTarrifType()
    {
        return this.tarrifType;
    }


}