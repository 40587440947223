import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../../common-data.service';
import * as mapsData from 'devextreme/dist/js/vectormap-data/world.js';
import { ViewportCoordinate, Service } from './../../VectorService';
import { Users } from '../../../services/Users';
import { UserAuthService } from '../../../services/auth.service';
import { ProjectService } from '../../../services/ProjectService';
import { Router } from '@angular/router';
import {TarrifModule} from '../../../models/tarrifModule';
import {TarrifTypeModule} from '../../../models/tarrifTypeModule';
import {environment} from '../../../environments/environment';

import * as shape from 'd3-shape';
declare var window: any;

@Component({
  selector: 'app-dashboard',
  providers: [Service],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

    hasTarrif:boolean = false;
    browser;
    timer;
    hasNewTarrif=false;
    userPanel;
    messages=[];
       
  constructor(
      private _commondata: CommonDataService, 
      service: Service ,
      public user_model: Users,
      public auth: UserAuthService,
      public router: Router,
      public projectService: ProjectService
    ) 
    {
      
    }
     ngOnInit() {
        setTimeout(_ => this._commondata.showLoader(false), 200);
        let user = this.user_model.getLocalUser();
        console.log("///////////////////////////////");
        console.log(user);
        console.log("///////////////////////////////");
            if(user.getId())
            {
                if(user.getUserAccess())
                {
                    this.hasTarrif = true;
                }  
                this.userPanel = user.getChildren();    
                this.getMessage();
            }
            else
            {
                
                    this.router.navigate(['/login']);
            }
        
  }
  
  tarrif(numtype)
  {
      let lthis = this;
    this.user_model.getProf().then(
        (res)=>{
            console.log(res);
            var CurrentDate = new Date();
            CurrentDate.setMonth(CurrentDate.getMonth() + 1);
            let data = 
            {
                "dateStart": this.formatDate(new Date().getTime()) + " 00:00:00",
                "dateEnd": this.formatDate(CurrentDate.getTime()) + " 23:59:59",
                'User':res["id"].toString(),
                'tarrifType':numtype
            };
            this.user_model.setTarrif(data).then(
            (res: any)=>{
                let user  = lthis.user_model.getLocalUser();
                console.log("yyyyyyyyyyyyyyyy " + res);
                let tarrif: TarrifModule = new TarrifModule();
                tarrif.initialize(JSON.parse(res.text()));
                this.user_model.getTarrifType(numtype).then(
                (tarrifType: TarrifTypeModule)=>{
                   
                    tarrif.setTarrifType(tarrifType);
                    user.setTarrif(tarrif);
                    user.getTarrif();
                    var userType = 4;
                    
                    if(tarrifType.getId()>1)
                    {
                        userType=2;
                    }
                    
                    user.setTypeUser(userType);    
                    console.log(user);
                    let data={
                        "TypeUser":userType+""
                    };
                    this.user_model.updateUser(user.getId(),data).then(
                    (res: any)=>{
                        lthis.user_model.saveLocalUser(user);
                        window.location.href = window.location.href;
                    },
                    (err: any)=>{
                        
                    });
                    
                    
                },
                err=>{
                    
                });
                
            },
            err=>{

            });
        },
        err=>{

        }
    );  
    
  }
  
    OpenMenu(sectionName, Wrapdiv) {
        var CurrentCls = document.getElementById(sectionName).getAttribute("class");
        if (CurrentCls == "dropdown-menu")
        {
            document.getElementById(sectionName).setAttribute("class", "dropdown-menu show");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "true");
            document.getElementById(Wrapdiv).setAttribute("class", "btn-group info-drop show");
        }
        else {
            document.getElementById(sectionName).setAttribute("class", "dropdown-menu");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "false");
            document.getElementById(Wrapdiv).setAttribute("class", "btn-group info-drop");
        }
    }
    
    formatDate(date) {
        let d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2){ month = '0' + month;}
        if (day.length < 2){ day = '0' + day;}

        return [year, month, day].join('-');
    }
    
    goSelected(tarrifId)
    {
        this.browser = window.open(environment.backendUrl + '/api/payment/create/' + this.user_model.getLocalUser().getId() + "/" 
        + tarrifId , "_blank", "hideurlbar=yes, hidenavigationbuttons=true");
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.timer = setInterval(_ =>this.checkStatusWindow(), 1000);
        
        
        
//            browser.show();
//            browser.on('exit').subscribe((event: InAppBrowserEvent) =>
//            {
//                this.payment_provider.checkPayment(true);
//            });
//            browser.on('loadstop').subscribe((event: InAppBrowserEvent) =>
//            {
//                if (event.url != null && (event.url.includes("/api/payment/complete") || event.url.includes("/api/payment/cancel")))
//                {
//                    browser.close();
//                }
//            });
    }
    
    checkStatusWindow()
    {
        if (this.browser.closed) {
            // The window is closed.
            clearInterval(this.timer);
            if(/*this.browser.URL.indexOf("/api/payment/complete") > -1*/ true) {
                setTimeout(_ => this._commondata.showLoader(false), 200);
                this.browser.closed;
                //clearInterval(this.timer);
                
                this.user_model.getMyPayments().then(
                (payments: any)=>{
                    let user  = this.user_model.getLocalUser();
                    console.log(payments);
                    console.log("1");
                    if(payments != null){
                        console.log("2");
                        let payment=payments[payments.length-1];
                        console.log(payment);
                        if(user.getTarrif().getId()!=null)
                        {
                            console.log("3");
                            console.log(user.getTarrif());
                            if(user.getTarrif().checkNewPayment(payment))
                            {
                                console.log("4");
                                this.hasNewTarrif = true;
                            }
                            if(this.hasNewTarrif)
                            {
                                console.log("5");
                                this.setNewTarrif(payment);
                            }
                        }
                        else
                        {
                            console.log("6");
                            this.setNewTarrif(payment);
                        }
                    }else{
                    console.log("6");
                    }

                },
                err=>{

                });


            }
        }
            
    }
    
    setNewTarrif(payment)
    {
        if(payment.getStatus()=="prepare")
        {
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.tarrif(payment.getTarrifTypeId());
        }
    }
    
    getMessage()
    {
        let user = this.user_model.getLocalUser();
        if(this.messages)
        {
            this.projectService.getMyProjects().then(
            (res:any)=>{
                console.log(res);
                for(let project of res)
                {
                    for(let comment of project.getComments())
                    {
                        if(comment.user.id != user.getId())
                        {
                            this.messages.push(comment);
                        }
                    }
                }
                
            },
            (err)=>{
                
            });
        }
        
    }
    
    checkUsers()
    {
        if(this.userPanel==null)
        {
            return false;
        }
        else
        {
            if(this.userPanel.length==0)
            {
                return false;
            }
        }
        
        return true;
        
    }
    checkMsg()
    {
        if(this.messages==null)
        {
            return false;
        }
        else
        {
            if(this.messages.length==0)
            {
                return false;
            }
        }
        
        return true;
    }
    
    getProfile(child)
    {
        
        if(child.image!=null)
        {
            return this.user_model.getUrl()+"/upload/profile/"+child.image;
        }
        
        return "assets/images/team/05.jpg"
    }
  
}