﻿import { Component, OnInit } from '@angular/core';
import { AppModule } from './../../app.module';
import { CommonDataService } from './../../common-data.service';
import { Users } from '../../../services/Users';
import { UserAuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.scss']
})
export class resetPasswordComponent implements OnInit {

    public elementRef;
    wPurl="";
    token = false;
    user;
    isVisibleError = false;
    newPasswordForm;
    sendPost;
    
    constructor(
        public router: Router,
        public _commondata: CommonDataService,
        public user_model: Users,
        public auth: UserAuthService,
        translate: TranslateService,
        public route: ActivatedRoute,
        private frmbuilder:FormBuilder,
    ) { 
        this.newPasswordForm=frmbuilder.group({  
                password:new FormControl(null, [Validators.required,Validators.minLength(5)]),
            });
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('es');

        // the lang to use, if the lang isn't available, it will use the current loader to get them      
        translate.use('es');    
    }

    ngOnInit() {
        
        setTimeout(_ => this._commondata.showLoader(true), 10);
        this.route.params.subscribe(
            (resp: any[]) =>
            {
                console.log(resp);
                if(resp["tokenKey"].length > 0)
                {
                    this.token = resp["tokenKey"];
                    this.getUser();
                }
                else
                {
                    this.router.navigate(['/']);
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            }
        );
    }
    
    getUser()
    {
        let response = this.user_model.getUsersByToken(this.token).then(
                (res:any)=>{
                    console.log(res);
                    this.user = res;
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                },
                (err:any)=>{
                    
                });    
        
    }
    newPassword(data)
    {
        console.log(this.newPasswordForm.valid);
        if (this.newPasswordForm.valid) {
            setTimeout(_ => this._commondata.showLoader(true), 200);
            this.sendPost = {
                'plainPassword':data.value.password,
            };
            this.user_model.updateUser(this.user.getId(),this.sendPost).then(
                (res) =>
                {
                        let response = this.auth.login({
                            'username':this.user.username,
                            'password':data.value.password
                        }).then(
                            (res)=>{
                                this.user_model.getProf().then(
                                    (res)=>{
                                        this.router.navigate(['/']);
                                    },
                                    err=>{
                                        this.isVisibleError = true;
                                        setTimeout(_ => this._commondata.showLoader(false), 200);
                                    }
                                );
                            },
                            err=>{
                                this.isVisibleError = true;
                                setTimeout(_ => this._commondata.showLoader(false), 200);

                            }
                        );
                },
                (err) =>
                {
                    this.isVisibleError = true;
                     setTimeout(_ => this._commondata.showLoader(false), 200);
                }
            );
        } else {
              this.validateAllFormFields(this.newPasswordForm);
              this.isVisibleError = true;
              return;
        }
    }
    
    validateAllFormFields(formGroup: FormGroup) {        
      Object.keys(formGroup.controls).forEach(field => { 
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {            
              control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {       
              this.validateAllFormFields(control);           
            }
        });

    }
    
}
