import {Injectable} from '@angular/core';
import {Api} from '../providers/api';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {User,UserStorageKey} from '../models/user';
import {Client} from '../models/client';
import {TarrifModule} from '../models/tarrifModule';
import {TarrifTypeModule} from '../models/tarrifTypeModule';
import {PaymentModule} from '../models/PaymentModule';
import {RequestOptions, Headers} from '@angular/http';

@Injectable()
export class Users
{
    constructor(private api: Api) {}

    getProf()
    {
        let bearer = localStorage.getItem('token');
        let headers = new Headers({"Authorization": "Bearer " + bearer});
        let options = new RequestOptions({headers: headers});
        console.log(bearer);
        console.log(headers);
        console.log(options);

        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/me', '', options).subscribe(
                (res: any) =>
                {
                    console.log("aaaaaaaaaaaaaaaaa: "+JSON.parse(res.text()));
                    let user: User = lthis.getLocalUser();
                    user.initialize(JSON.parse(res.text()));
                    console.log(user);
                    console.log(JSON.parse(res.text()));
                    console.log(JSON.parse(res.text())["tarrifs"]);
                    if(JSON.parse(res.text())["tarrifs"].length>0)
                    {
                        let tarrifs = Object.values(JSON.parse(res.text())["tarrifs"]);
                        let currentTarrif = tarrifs[tarrifs.length-1];
                        
                        lthis.api.get('api/tarrifs/'+currentTarrif.id, '').subscribe(
                        (res: any) =>
                        {
                            console.log(res);
                            let tarrif: TarrifModule = new TarrifModule();
                            tarrif.initialize(JSON.parse(res.text()));
                            lthis.api.get(JSON.parse(res.text())["tarrifType"].substr(1), '').subscribe(
                            (res: any) =>
                            {
                                let tarrifType: TarrifTypeModule = new TarrifTypeModule();
                                tarrifType.initialize(JSON.parse(res.text()));
                                tarrifType.setTarrifType(JSON.parse(res.text())["type"]);
                                tarrif.setTarrifType(tarrifType);
                                user.setTarrif(tarrif);
                                console.log(user.getTarrif());
                                lthis.saveLocalUser(user);
                                console.log(res);
                                
                                
                                
                                resolve(user);
                            },
                            err =>
                            {
                                console.log(" Error.. \n " + err);
                                resolve(err);
                            });
                            
                        },
                        err =>
                        {
                            console.log(" Error.. \n " + err);
                            resolve(err);
                        });
                    }else{
                    
                        if(user.hasParentTarrif())
                        {
                            let currentParentTarrifId = user.getCurrentParentTarrifId();
                            lthis.getTarrif(currentParentTarrifId).then(
                            (res :any)=>{
                                let tarrif: TarrifModule = new TarrifModule();
                                tarrif.initialize(JSON.parse(res.text()));
                                user.setTarrif(tarrif);
                                console.log(user);
                                lthis.api.get(JSON.parse(res.text())["tarrifType"].substr(1), '').subscribe(
                                (res: any) =>
                                {
                                    let tarrifType: TarrifTypeModule = new TarrifTypeModule();
                                    tarrifType.initialize(JSON.parse(res.text()));
                                    tarrifType.setTarrifType(JSON.parse(res.text())["type"]);
                                    tarrif.setTarrifType(tarrifType);
                                    user.setTarrif(tarrif);
                                    console.log(user);
                                    lthis.saveLocalUser(user);
                                    resolve(user);
                                },
                                err=>{
                                    
                                });
                            },
                            err=>{

                            
                            });
                            
                        }
                        else
                        {
                            console.log(user);
                            lthis.saveLocalUser(user);
                            resolve(user);
                        }
                        
                    }
                    
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                    resolve(err);
                });
        });
    }
    
    getUsers()
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/users').map(res => res.json()).subscribe(
                (res: any) =>
                {
                    let users = [];
                    console.log(res);
                    for(let i = 0; i < res.length; i++)
                    {
                        let user: User = new User();
                        user.initialize(res[i]);
                        users.push(user);
                    }
                    resolve(users);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                });
        });
    }
    
    getUsersBy(col,value)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/user/by/'+col+"/"+value).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    let user: User = new User();
                    user.initialize(res);
                   
                    resolve(user);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                });
        });
    }
    
    getUsersByToken(token)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/users/token/'+token).map(res => res.json()).subscribe(
                (res: any) =>
                {
                        let user: User = new User();
                        user.initialize(res);
                        resolve(user);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                });
        });
    }
    
    getUserType(id)
    {
        let user: User = this.getLocalUser();
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/users/type/'+id).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    user.setTypeUser(res.id);
                    
                    resolve(user);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                });
        });
    }
    
    deleteUser(idUser)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.delete('api/users/'+idUser).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    resolve(res);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                });
        });
    }
    
    getMyUsers()
    {
        let lthis = this;
        let user: User = lthis.getLocalUser();
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/my/users/'+user.getId()).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    let users = [];
                    console.log(res);
                    for(let i = 0; i < res.length; i++)
                    {
                        let user: User = new User();
                        user.initialize(res[i]);
                        users.push(user);
                    }
                    resolve(users);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                });
        });
    }
    
    setTarrif(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/tarrifs', data).subscribe(
                (res: any) =>
                {
                    console.log(res);
                    resolve(res);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                });
        });
    }
    
    getTarrif(id)
    {
        let lthis = this;
        let user: User = lthis.getLocalUser();
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/tarrifs/'+id, '').subscribe(
                (res: any) =>
                {
                    console.log(res);
                    resolve(res);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                });
        });
    }
    
    getTarrifType(id)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/tarrif-types/'+id, '').subscribe(
                (res: any) =>
                {
                    console.log(res);
                    let tarrifType: TarrifTypeModule = new TarrifTypeModule();
                    tarrifType.initialize(JSON.parse(res.text()));
                    tarrifType.setTarrifType(JSON.parse(res.text())["type"]);
                    resolve(tarrifType);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                });
        });
    }
    getMyPayments()
    {
        let lthis = this;
        let user: User = lthis.getLocalUser();
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/my/payments/'+user.getId(), '').map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log(res);
                    let payments = [];
                    for(let pay of res)
                    {
                        let payment: PaymentModule = new PaymentModule();
                        payment.initialize(pay);
                        payments.push(payment)
                    }
                    resolve(payments);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                });
        });
    }

    register(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('custAuth/createUserClient', data).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Register result ", res);
                    resolve(res["hydra:member"][0]);
                },
                (err) =>
                {
                    console.error('error register ', err);
                    reject(err);
                });
        });
    }

    login(email, pass)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            var data = {
                "_username": email,
                "_password": pass,
                "_submit": "Entrar"
            };
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.form('login_check', data, opts).subscribe(
                (res: any) =>
                {
                    console.log("Loged backend user ");
                    //                    resolve(res);
                    lthis.check_login().then(
                        (resp) =>
                        {
                            resolve(resp);
                        });
                },
                (err) =>
                {
                    if (err.status == 403)
                    {
                        console.log("Loged customer ");

                        lthis.check_login().then(
                            (resp) =>
                            {
                                resolve(resp);
                            },
                            (err) =>
                            {
                                reject(err);
                            });
                    } else
                    {
                        console.error('error login ', err);
                        reject(err);
                    }
                });
        });
    }
    
    updateUser(userId,data)
    {
        let headers = new Headers({"Content-Type": "application/json"});
        let options = new RequestOptions({headers: headers});
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.put("api/users/"+userId, data, options).map(res => res.json()).subscribe(
                (res) =>
                {
                    let user: User = new User();
                    user.initialize(res);
                    resolve(user);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                    reject(err);
                }
            );
        });
    }
    
    /*
     * This methods call to get session and check if user is logged
     */
    check_login()
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.form('custAuth/login', null, opts).map(res => res.json()).subscribe(
                (res: any) =>
                {
                    console.log("Check Login result ", res);
                    resolve(res);
                },
                (err) =>
                {
                    console.error('error Check login ', err);
                    reject(err);
                });
        });
    }
    logout()
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('api/logout', "", opts).subscribe(
                (res: any) =>
                {
                    console.log("logout result ", res);
                    resolve(res.ok);
                },
                (err) =>
                {
                    console.error('error logout ', err);
                    reject(err);
                });
        });
    }
    
    forgotPassword(email)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/reset-password/' + email).subscribe(
                (res: any) =>
                {
                    resolve(res);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                    reject(err);
                }
            );
        });
    }
    
    setCredentials(data)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            let opts = new RequestOptions;
            opts.withCredentials = true;
            lthis.api.post('customer/setCredentials', data, opts).map(res => res.json()).subscribe(
                (res: any[][]) =>
                {
                    console.log("set Profile result ", res);
                    if (res["hydra:member"][0].succes)
                    {
                        let objClient = new Client();
                        objClient.initialize(res["hydra:member"][0].data);
                        resolve(objClient);
                    } else
                    {
                        console.error('error setting profile ', res["hydra:member"][0].data);
                        //needs to show form errors
                        reject();
                    }
                },
                (err) =>
                {
                    console.error('error setting profile ', err);
                    reject(err);
                });
        });
    }
    
    getMessages(idUser)
    {
        let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.get('api/get/messages/project' + idUser).subscribe(
                (res: any) =>
                {
                    resolve(res);
                },
                err =>
                {
                    console.log(" Error.. \n " + err);
                    reject(err);
                }
            );
        });
    }
    
    uploadProfile(image: File)
    {
//      let bearer = localStorage.getItem('token');
//        let headers = new Headers({"Authorization": "Bearer " + bearer});
//        let options = new RequestOptions({headers: headers});
      const formData = new FormData();
      formData.append('file', image, this.getLocalUser().getId()+"");
      console.log(formData);
      let lthis = this;
        return new Promise(function (resolve, reject)
        {
            lthis.api.post('api/users/uploadProfile', formData).subscribe(
            (res: any) =>
            {
                console.log(res);
                resolve(res);
            },
            (err) =>
            {
                console.log(" Error.. \n " + err);
                reject(err);
            });
        });
    }
    
    getLocalUser()
    {
        let user: User = new User();
        user.initialize(JSON.parse(localStorage.getItem(UserStorageKey)));
        return user;
    }
    saveLocalUser(user: User)
    {
        localStorage.setItem(UserStorageKey, (JSON.stringify(user)));
    }
    clearLocalStorage()
    {
        localStorage.clear();
    }
    removeLocalUser()
    {
        localStorage.removeItem(UserStorageKey);
    }
    getDocUrl()
    {
        return this.api.getDocUrl();
    }
    getWPUrl()
    {
        return this.api.getWPUrl();
    }
    getUrl()
    {
        return this.api.getUrl();
    }
    getAnswersUrl()
    {
        return this.api.getAnswersUrl();
    }    
}

