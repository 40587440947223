import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

export class PaymentModule extends Base_Model
{

    private id: number = null;
    private tarrifType: string = null;
    private user: string = null;
    private status: string = null;
    private amount: number = null;
    private createdDate: string = null;
    private updatedDate: string = null;
    
    public getId()
    {
        return this.id;
    }
    public getTarrifType()
    {
        return this.tarrifType;
    }
    public getTarrifTypeId()
    {
        let res = this.tarrifType.split("/");
        return res[res.length-1];
    }
    public getUser()
    {
        return this.user;
    }
    public getStatus()
    {
        return this.status;
    }
    public getAmount()
    {
        return this.amount;
    }
    public getCreatedDate()
    {
        return this.createdDate;
    }
    public getUpdatedDate()
    {
        return this.updatedDate;
    }
    
    

}