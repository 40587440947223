﻿import { Component, OnInit, Input } from '@angular/core';
import { CommonDataService } from './../../../common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Form } from './../../../../services/Form';
import { BlockService } from './../../../../services/BlockService';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../shared/_services/index';
import { FormBuilder,FormControl, FormGroup, Validators, NgForm,FormsModule } from '@angular/forms';
import { FieldsService } from './../../../../services/FieldsService';
import { FieldsModule } from './../../../../models/FieldsModule';

import * as shape from 'd3-shape';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit 
{
    idForm = false;
    blocks = [];
    url = "";
    selectImg = null;
    @Input() statusField;
    selectedFile: File = null;
    @Input() currentBlockId;
    imgPreview=false;
    @Input() currentFieldId = 0;
    currentModal = "addField";
    nameError = false;
    constructor(
    private _commondata: CommonDataService,
    translate: TranslateService,
    public form: Form,
    public router: Router,
    public route: ActivatedRoute,
    private modalService: ModalService,
    public fieldsService: FieldsService,
    ) 
    {
        
    }
    ngOnInit() 
    {
        //document.querySelector("body").addEventListener("editImageRefresh", (e:Event) => this.onInit());
        this.onInit(); 
    }
    onInit() 
    {
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.fieldsService.getAllImage().then(
            (data : any)=>{
                console.log(data);
                let option = "";
                for(let i=0;i<data.length;i++)
                {
                    let valu = data[i].split("/");
                    let imgName = valu[valu.length-1]; 
                    
                    option = option + "<div style='width: 24%;display: inline-block;' class='dropdown-item col-xl-3'><img style='width:100%;cursor:pointer;' class='addImg' data-img='"+imgName+"' src='"+data[i]+"'></div>";
                }
                document.querySelector("#submenu").innerHTML = option;
                
                let elements = document.getElementsByClassName("addImg");
                for(let y=0;y<elements.length;y++)
                {
                    elements[y].addEventListener("click", (e:Event) => this.selectingImg(elements[y].getAttribute("src"),elements[y].getAttribute("data-img")));
                }
                
                if(this.currentFieldId>0)
                {

                    this.currentModal="editFieldModal";
                    let response = this.fieldsService.getImage(this.currentFieldId).then(
                    (res:FieldsModule)=>{
                        let data = JSON.parse(res.getContent());
                        (<HTMLInputElement>document.querySelector("#imgleyend")).value = data.l;
                        (<HTMLInputElement>document.querySelector("#imagenTitle")).value = res.getQuestion();
                        if(document.querySelector('[data-img="'+res.getImage()+'"]')!=null)
                        {
                            this.selectingImg(document.querySelector('[data-img="'+res.getImage()+'"]').getAttribute("src"),res.getImage());
                        }
                        setTimeout(_ => this._commondata.showLoader(false), 200);
                    },
                    err=>{
                                console.log("form_no");
                                setTimeout(_ => this._commondata.showLoader(false), 200);

                    }
                );


                }
                else
                {
                    setTimeout(_ => this._commondata.showLoader(false), 200);
                } 

              },
              error=>{
                console.log(error);
              }
            );
        
    }
    
    getInit()
    {
        this.closeModal(this.currentModal);
        setTimeout(_ => this._commondata.showLoader(true), 200);
        var event = document.createEvent('Event');
        event.initEvent('editFormRefresh', true, true);
        document.querySelector("body").dispatchEvent(event);
    }
      
    selectingImg(src,imgName)
    {
        this.selectImg = imgName;
        this.OpenMenu("submenu", "wrapDiv");
        this.url = src;
        this.imgPreview=true;
    }
    
    openModal(id: string) {
        this.modalService.open(id);
        this.statusField = false;
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.statusField = false;
    }
    
      

    onFileChanged(event) {
    this.selectedFile = event.target.files[0];
        if (event.target.files && event.target.files[0]) {
            this.imgPreview=true;
            var reader = new FileReader();

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event) => 
            { // called once readAsDataURL is completed
              this.url = reader.result;
            }
        }
    }

    
    uploadFile(imgId) {
        
        this.fieldsService.uploadImage(imgId,this.selectedFile).then(
            (data : any)=>{
                 console.log(data)
                 setTimeout(_ => this.getInit(), 1000);
              },
              error=>{
                console.log(error);
              }
            );
        
    }
    
    createImage()
    {
        let title = (<HTMLInputElement>document.getElementById("imagenTitle")).value;
        if(title=="")
        {
            this.nameError = true;
            console.log("HERE");
            return;
        }
        else
        {
            this.nameError = false;
        }
        
        setTimeout(_ => this._commondata.showLoader(true), 200);
        
        let content = {
            "l":(<HTMLInputElement>document.getElementById("imgleyend")).value,
        };
        let data = {
            "question" : title,
            "block" : this.currentBlockId,
            "content" : JSON.stringify(content),
            "image" : ""
        };
        console.log(this.selectImg);
        if(this.selectImg!=null)
        {
            data = {
            "question" : title,
            "block" : this.currentBlockId,
            "content" : JSON.stringify(content),
            "image" : this.selectImg
            };
        }
        
        if(this.currentFieldId>0)
        {
            let response = this.fieldsService.updateImage(this.currentFieldId,data).then(
                (res:FieldsModule)=>{
                    console.log(res);
                    console.log("done");
                    if(this.selectedFile != null)
                    {
                        this.uploadFile(res.getId());
                    }
                    else
                    {
                        setTimeout(_ => this.getInit(), 1000);
                    }

                },
                err=>{
                            console.log("form_no");

                }
            );
        }
        else
        {
            let response = this.fieldsService.createImage(data).then(
                (res:FieldsModule)=>{
                    console.log(res);
                    console.log("done");
                    if(this.selectedFile != null)
                    {
                        this.uploadFile(res.getId());
                    }
                    else
                    {
                        setTimeout(_ => this.getInit(), 1000);
                    }

                },
                err=>{
                            console.log("form_no");

                }
            );
        }
            
    }
    
    OpenMenu(sectionName, Wrapdiv) {
        var CurrentCls = document.getElementById(sectionName).getAttribute("class");
        if (CurrentCls == "dropdown-menu full-width")
        {
            document.getElementById(sectionName).setAttribute("class", "dropdown-menu show full-width");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "true");
            document.getElementById(Wrapdiv).setAttribute("class", "btn-group info-drop info-drop-box show full-width");
        }
        else {
            document.getElementById(sectionName).setAttribute("class", "dropdown-menu full-width");
            document.getElementById(sectionName).previousElementSibling.setAttribute("aria-expanded", "false");
            document.getElementById(Wrapdiv).setAttribute("class", "btn-group info-drop info-drop-box full-width");
        }
    }
    
}
