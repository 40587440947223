import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {FieldsModule} from './FieldsModule';
export class BlockModule extends Base_Model
{

    private id: number = null;
    private name: string = null;
    private indexName: string = null;
    private sort: string = null;
    private color: string = null;
    private colums: number = null;
    private optional: number = null;
    private question: number = null;
    private idparent: number = null;
    private blocks: BlockModule[] = [];
    private fields: FieldsModule[] = [];
    
    public getId()
    {
        return this.id;
    }
    public getName()
    {
        return this.name;
    }
    public getIndexName()
    {
        return this.indexName;
    }
    public setIndexName(indexName)
    {
        this.indexName = indexName;
        return this.indexName;
    }
    public getSort()
    {
        return this.sort;
    }
    public getColor()
    {
        return this.color;
    }
    public getColums()
    {
        return this.colums;
    }
    public getOptional()
    {
        return this.optional;
    }
    public setOptional(optional)
    {
        this.optional = optional;
        return this;
    }
    public getIdparent()
    {
        return this.idparent;
    }
    public setBlock(block)
    {
        return this.blocks.push(block);
    }
    public getBlocks()
    {
        return this.blocks;
    }
    public getQuestion()
    {
        return this.question;
    }
    public setFields(field)
    {
        return this.fields.push(field);
    }
}