﻿import { Component, OnInit } from '@angular/core';
import { CommonDataService } from './../common-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectService } from './../../services/ProjectService';
import { Users } from './../../services/Users';
import { ProjectModule } from './../../models/ProjectModule';
import { ModalService } from '../shared/_services/index';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectComponent implements OnInit {

    projects;
    inicial;
    progress;
    done;
    projectId;
    constructor
    (
        private _commondata: CommonDataService,
        public projectService: ProjectService,
        public user_model: Users,
        private modalService: ModalService,
        translate: TranslateService,
    ) 
    { 
        
        let respuesta = translate.get('Inicial').subscribe(res => { this.inicial = res; });
        respuesta = translate.get('En progreso').subscribe(
        res => {
            console.log("translate:")
            console.log(res); 
            this.progress = res; 
        }
        );
        respuesta = translate.get('Hecho').subscribe(res => { this.done = res; }); 
    }
    ngOnInit() 
    {   
        setTimeout(_ => this._commondata.showLoader(true), 200);
        this.onInit();
    }
    
    onInit()
    {
        let response = this.projectService.getMyProjects().then(
            (res:any)=>{
                console.log(res);
                this.projects = res;
                setTimeout(_ => this._commondata.showLoader(false), 200);
            },
            err=>{
                console.log(err);
            }
        );
    }
    
    deleteProject(idProject)
    {
        setTimeout(_ => this._commondata.showLoader(true), 10);
        let response = this.projectService.deleteProject(this.projectId).then(
            (res:any)=>{
                this.onInit();
            },
            err=>{
                console.log(err);
            }
        );
    }
    
    getStatus(status)
    {
        console.log(status);
        switch(status)
        {
            case 0:
                return this.inicial;
            case 1:
                return this.progress;
            case 2:
                return this.done;
        }
    }
    
    getProfile(child)
    {
        if(child!=null)
        {
            if(child.image!=null)
            {
                return this.user_model.getUrl()+"/upload/profile/"+child.image;
            }
        }
        return "assets/images/team/05.jpg"
    }
    
    deleteProjectModal(idProject)
    {
        this.projectId = idProject;
        this.modalService.open("deleteProyectModal");
    }
    
    closeModal(id)
    {
        this.projectId = "";
        this.modalService.close(id);
    }
    
    
    
    
}
