﻿import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { FormComponent } from './form/form.component';
import { PreviewComponent } from './preview/preview.component';
import { EditFormComponent } from './form/editForm/editForm.component';
import { ProjectComponent } from './projects/projects.component';
import { SelectFormComponent } from './projects/selectForm/form.component';
import { ConfigComponent } from './config/config.component';
import { SelectUserComponent } from './projects/selectUser/selectUser.component';
import { EditProjectsComponent } from './projects/editProject/editProjects.component';
import { AccessProjectsComponent } from './projects/accessProject/accessProjects.component';
import { UsersComponent } from './users/users.component';
import { CuentasComponent } from './cuentas/cuentas.component';

import { Dashboard06Component } from './dashboards/dashboard06/dashboard06.component';

import { MailComponent } from './mail/mail.component';


import { EditorComponent } from './forms/editor/editor.component';
import { FormInputComponent } from './forms/form-input/form-input.component';
import { FormValidationComponent } from './forms/form-validation/form-validation.component';
import { InputGroupComponent } from './forms/input-group/input-group.component';
import { ToastrComponent } from './forms/toastr/toastr.component';


import { FontawesomeIconComponent } from './icons/fontawesome-icon/fontawesome-icon.component';
import { ThemifyIconsComponent } from './icons/themify-icons/themify-icons.component';
import { WeatherIconComponent } from './icons/weather-icon/weather-icon.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectSummaryComponent } from './pages/project-summary/project-summary.component';
import { ProfileComponent } from './pages/profile/profile.component';
//import { ChatComponent } from './chat/chat.component';
import { AppContactsComponent } from './pages/app-contacts/app-contacts.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { FileManagerComponent } from './pages/file-manager/file-manager.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { BlankComponent } from './pages/blank/blank.component';
import { LayoutContainerComponent } from './pages/layout-container/layout-container.component';
import { ErrorComponent } from './pages/error/error.component';
import { FaqsComponent } from './pages/faqs/faqs.component';

import { MapsComponent } from './maps/maps.component';
import { TimelineComponent } from './timeline/timeline.component';
import { LoginComponent } from './auth/login/login.component';
import { resetPasswordComponent } from './auth/resetPassword/resetPassword.component';
import { LayoutComponent } from './shared/_layout/layout/layout.component';
import { Layout1Component } from './shared/_layout/layout1/layout1.component';
import { Layout2Component } from './shared/_layout/layout2/layout2.component';
import { Layout3Component } from './shared/_layout/layout3/layout3.component';
import { Layout4Component } from './shared/_layout/layout4/layout4.component';

import { RegisterComponent } from './auth/register/register.component';
import { LockscreenComponent } from './auth/lockscreen/lockscreen.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', component: DashboardComponent, pathMatch: 'full' },
            { path: 'form', component: FormComponent },
            { path: 'form/editForm', component: EditFormComponent },
            { path: 'form/editForm/:idForm', component: EditFormComponent },
            { path: 'projects', component: ProjectComponent },
            { path: 'projects/selectForm', component: SelectFormComponent },
            { path: 'projects/editProject', component: EditProjectsComponent },
            { path: 'projects/editProject/:idProject', component: EditProjectsComponent },
            { path: 'projects/selectUser', component: SelectUserComponent },
            { path: 'projects/selectUser/:idProject', component: SelectUserComponent },
            { path: 'projects/accessProject/:idProject', component: AccessProjectsComponent },
            { path: 'preview/:idProject', component: PreviewComponent }, 
            { path: 'users', component: UsersComponent },
            { path: 'cuentas', component: CuentasComponent },
            { path: 'config', component: ConfigComponent },
            //{ path: 'calendarProject', component: CalendarProjectComponent },
//            {
//                path: 'accordions',
//                component: AccordionsComponent
//            }
//            ,
//            {
//                path: 'alerts',
//                component: AlertsComponent
//            }
//            ,
//            {
//                path: 'buttons',
//                component: ButtonsComponent
//            }
//            ,
//            {
//                path: 'colorpicker',
//                component: ColorpickerComponent
//            }
//            ,
//            {
//                path: 'dropdown',
//                component: DropdownComponent
//            }
//            ,
//            {
//                path: 'lists',
//                component: ListsComponent
//            }
//            ,
//            {
//                path: 'modal',
//                component: ModalComponent
//            }
//            ,
//            {
//                path: 'nav',
//                component: NavComponent
//            }
//            ,
//            { path: 'nicescroll', component: NicescrollComponent },
//            { path: 'pricingtable', component: PricingtableComponent },
//            
            //{ path: 'data-html-table', component: DataHtmlTableComponent },
            //{ path: 'data-local', component: DataLocalComponent },
            //{ path: 'data-table', component: DataTableComponent },
//            { path: 'datepicker', component: DatepickerComponent },


//            { path: 'tabs', component: TabsComponent },
//            { path: 'typography', component: TypographyComponent },
//            { path: 'popover-tooltips', component: PopoverTooltipsComponent },
//            { path: 'progress', component: ProgressComponent },
//            { path: 'switch', component: SwitchComponent },
//            { path: 'sweetalert2', component: Sweetalert2Component },



            
            //{ path: 'chart', component: ChartComponent },
            //{ path: 'calendar', component: CalendarComponent },
            { path: 'editor', component: EditorComponent },
            { path: 'form-input', component: FormInputComponent },
            { path: 'form-validation', component: FormValidationComponent },
            { path: 'input-group', component: InputGroupComponent },
            { path: 'toastr', component: ToastrComponent },


            { path: 'fontawesome-icon', component: FontawesomeIconComponent },
            { path: 'themify-icons', component: ThemifyIconsComponent },
            { path: 'weather-icon', component: WeatherIconComponent },
            { path: 'projects', component: ProjectsComponent },
            { path: 'profile', component: ProfileComponent },
            //{ path: 'chat', component: ChatComponent },
            { path: 'app-contacts', component: AppContactsComponent },
            { path: 'contacts', component: ContactsComponent },
            { path: 'file-manager', component: FileManagerComponent },
            { path: 'invoice', component: InvoiceComponent },
            { path: 'blank', component: BlankComponent },
            { path: 'layout-container', component: LayoutContainerComponent },
            { path: 'error', component: ErrorComponent },
            { path: 'faqs', component: FaqsComponent },

            { path: 'maps', component: MapsComponent },
            { path: 'timeline', component: TimelineComponent },
            { path: 'project-summary', component: ProjectSummaryComponent },
            { path: 'mail', component: MailComponent },
        ]
    },
    
    { path: 'login', component: LoginComponent },
    { path: 'reset-password', component: resetPasswordComponent },
    { path: 'reset-password/:tokenKey', component: resetPasswordComponent },

    {
        path: '', component: Layout1Component, children: [
            //{ path: 'dashboard02', component: Dashboard02Component, pathMatch: 'full' }
        ]
    }

    ,
    {
        path: '', component: Layout2Component, children: [
            //{ path: 'dashboard03', component: Dashboard03Component }
        ]
    }


    ,
    {
        path: '', component: Layout3Component, children: [
            //{ path: 'dashboard04', component: Dashboard04Component }
        ]
    }


    ,
    {
        path: '', component: Layout4Component, children: [
            //{ path: 'dashboard05', component: Dashboard05Component }
        ]
    }
    ,
    {
        path: '', component: Layout1Component, children: [
            { path: 'dashboard06', component: Dashboard06Component, pathMatch: 'full' }
        ]
    }
    , { path: 'register', component: RegisterComponent }
    , { path: 'lockscreen', component: LockscreenComponent }
];



@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
