import {Base_Model} from './base_model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {BlockModule} from './BlockModule';

export class FormModule extends Base_Model
{

    private id: number = null;
    private name: string = null;
    private active: string = null;
    private created: string = null;
    private blocks: BlockModule[] = [];
    private linequestions = {};

    public getId()
    {
        return this.id;
    }
    public getName()
    {
        return this.name;
    }
    public setName(name)
    {
        this.name = name;
        return this;
    }
    public getCreated()
    {
        return this.created;
    }
    public setCreated(date)
    {
        this.created = date;
        return this;
    }
    public getActive()
    {
        return this.active;
    }
    public setBlock(block)
    {
        return this.blocks.push(block);
    }
    public getBlocks()
    {
        return this.blocks;
    }
    public getLineQuestions()
    {
        return this.linequestions;
    }
    public setLineQuestions(linequestions)
    {
         this.linequestions = linequestions;
         return this;
    }
}